/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Suspense, useEffect, useState } from 'react'
import { Switch, Redirect, Route, RouteChildrenProps } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import styles from './basiclayout.module.less'
import { Menu, Dropdown, Modal, Spin } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { DownOutlined } from '@ant-design/icons'
import { getRouteList, getMenu } from '@/Router/routes.config'
import './baselayout.less'
import Cookies from 'js-cookie'
// import { useDispatch } from 'react-redux'
// 引用图片
import accountIcon from '@/assets/images/icon_account.svg'
import logoutIcon from '@/assets/images/icon_logout.svg'
import touxiang from '@/assets/images/img_touxiang_n.svg'
import AIEnv from '@/common/AIEnv'
import Pfee from '@/common/CommonPfee'
import Terminal from '@/common/CommonTerminal'

// import { deleteAllCase } from '@/api/case'
import { setUserOnline } from '@/api/schedule'
// import { getDicts } from '@/api/env'

const { SubMenu } = Menu
const win: any = window

let once = true
// interface MenuInfo {
//   key: React.Key
//   keyPath: React.Key[]
//   item: React.ReactInstance
//   domEvent: React.MouseEvent<HTMLElement>
// }

// const rMBO = () => {
//   console.log('你关闭了浏览器')
//   clearTimeout(win.__timerone__)
//   if (Cookies.get('userType') === 'student' || Cookies.get('userType') === 'teacher') {
//     // deleteAllCase({
//     //   userId: Cookies.get('userId'),
//     // })
//     // navigator.sendBeacon('/api/case/deleteAllCase', JSON.stringify({ userId: Cookies.get('userId') }))
//     fetch('/api/case/deleteAllCase', {
//       method: 'POST',
//       body: JSON.stringify({ userId: Cookies.get('userId') }),
//       keepalive: true,
//     }).then(async () => {
//       Cookies.remove('token')
//       Cookies.remove('userId')
//       Cookies.remove('userCode')
//       Cookies.remove('name')
//       Cookies.remove('username')
//       Cookies.remove('terminal')
//       Cookies.remove('userType')
//       Cookies.remove('encc')
//       sessionStorage.clear()
//     })
//   }
// }

// let _beforeUnload_time = 0,
//   _gap_time = 0
// const is_fireFox = navigator.userAgent.indexOf('Firefox') > -1 //是不是火狐浏览器
// ;(window as any).onunload = async () => {
//   _gap_time = new Date().getTime() - _beforeUnload_time
//   console.log(_gap_time, '_gap_time')
//   if (_gap_time <= 5) {
//     rMBO()
//   }
// }
// ;(window as any).onbeforeunload = () => {
//   _beforeUnload_time = new Date().getTime()
//   if (is_fireFox) {
//     rMBO()
//   }
// }

let TDonw: any = null
const startTimeDown = () => {
  if (TDonw) {
    win.TMWorker.clearInterval(TDonw)
    TDonw = null
  }
  TDonw = win.TMWorker.setInterval(() => {
    if (Cookies.get('userType') === 'student' || Cookies.get('userType') === 'teacher') {
      setUserOnline({
        userId: Cookies.get('userId'),
      })
    }
  }, 20000)
}

const getLink = () => {
  const type = Cookies.get('userType')
  let link = ''

  switch (type) {
    case 'super':
      link = '/dashboard/course-manage'
      break
    case 'admin':
      link = '/dashboard/course-manage'
      break
    case 'teacher':
      link = '/dashboard/teacher/home'
      break
    case 'student':
      link = '/dashboard/student/home'
      break
    default:
      link = '/dashboard/resouce-management/view'
      break
  }
  return link
}
// const getSubLink = () => {
//   const type = Cookies.get('userType');
//   const link = [];
//   if (type != 'student') {
//     const spl = location.pathname.split('/');
//     const al = '/' + spl[1] + '/' + spl[2];
//     link.push(al)
//   }
//   else {
//     link.push('/dashboard/student')
//   }
//   return link;
// }
const BasicLayout: React.FC<RouteChildrenProps & MenuProps> = (props) => {
  const location = useLocation()
  const hisatory = useHistory()
  // const dispatch = useDispatch()
  const [openKeys] = useState(() => {
    const type = Cookies.get('userType')
    let link = []
    if (type === 'student') {
      link.push('/dashboard/student')
    } else if (type === 'teacher') {
      link = ['/dashboard/teacher', '/dashboard/teaching-management', '/dashboard/system']
    } else {
      const spl = location.pathname.split('/')
      const al = '/' + spl[1] + '/' + spl[2]
      link = [al, '/dashboard/teaching-management']
    }
    return link
  })
  const [selectKeys, setSelectKeys] = useState([getLink()])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [topAIJupyter, setTopAIJupyter] = useState(0)
  const [showPfee, setShowPfee] = useState(false)
  const [showAiTerm, setshowAiTerm] = useState(false)

  const aiEnvchange = (val: any) => {
    console.log(val, '0000')
    if (val === 1) {
      setShowPfee(true)
    } else if (val === 2) {
      setshowAiTerm(true)
    }
  }

  const closePfee = () => {
    setShowPfee(false)
  }

  startTimeDown()
  // useEffect(() => {
  //   const type = Cookies.get('userType');
  //   let link = '';

  //   switch (type) {
  //     case 'super':
  //       link = '/dashboard/system/teacher-management'
  //       break;
  //     case 'admin':
  //       link = '/dashboard/course-manage'
  //       break;
  //     case 'teacher':
  //       link = '/dashboard/resouce-management/view'
  //       break;
  //     case 'student':
  //       link = '/dashboard/student/home';
  //       break;
  //     default:
  //       link = '/dashboard/resouce-management/view'
  //       break;
  //   }
  //   setSelectKeys([link])
  // })

  const setSessionStorageState = (key: string, val: any) => {
    const obj: any = {}
    obj[key] = val
    const V = JSON.stringify(obj)
    sessionStorage.setItem('__state__', V)
  }

  const getSessionStorageState = (key: string) => {
    const o: any = sessionStorage.getItem('__state__')
    if (o) {
      const V = JSON.parse(o)[key]
      return V
    }
  }

  const removeSessionStorageState = () => {
    sessionStorage.removeItem('__state__')
  }

  useEffect(() => {
    const top_Ai_upyter = (window as any).sessionStorage.getItem('__Top_Ai_Jupyter__') * 1
    console.log(top_Ai_upyter, '--top_Ai_upyter--')
    setTopAIJupyter(top_Ai_upyter)
  }, [])

  useEffect(() => {
    const { pathname } = location
    let key = ''
    ;(window as any).__History_change__ = 0

    const routeList = [
      {
        key: '/dashboard/system/teacher-management',
        word: 'dashboard/teacher-management/add',
      },
      {
        key: '/dashboard/system/teacher-management',
        word: 'dashboard/teacher-management/detail',
      },
      {
        key: '/dashboard/student-management',
        word: 'dashboard/student-management/add',
      },
      {
        key: '/dashboard/student-management',
        word: 'dashboard/student-management/detail',
      },
      {
        key: '/dashboard/course-management/view',
        word: 'dashboard/course-management/view/detail',
      },
      {
        key: '/dashboard/course-management/view',
        word: 'dashboard/course-management/view/operate',
      },
      {
        key: '/dashboard/course-management/grade',
        word: 'dashboard/course-management/grade/report',
      },
      {
        key: '/dashboard/student/home',
        word: '/dashboard/student/home/task',
      },
      {
        key: '/dashboard/student/home',
        word: '/dashboard/student/home/score-report',
      },
      {
        key: '/dashboard/student/complete',
        word: '/dashboard/student/complete/task',
      },
      {
        key: '/dashboard/student/complete',
        word: '/dashboard/student/complete/score-report',
      },
      {
        key: '/dashboard/teaching-management/studentstask',
        word: 'dashboard/teaching-management/studentstask/detail',
      },
      {
        key: '/dashboard/teaching-management/taskscore',
        word: 'dashboard/teaching-management/taskscore/detail',
      },
      {
        key: '/dashboard/teaching-management/taskscore',
        word: 'dashboard/teaching-management/taskscore/score',
      },
      {
        key: '/dashboard/teaching-management/debriefing',
        word: 'dashboard/teaching-management/debriefing/detail',
      },
      {
        key: '/dashboard/teaching-management/debriefing',
        word: 'dashboard/teaching-management/debriefing/checkbrie',
      },
    ]

    const wsc = [
      'dashboard/student/home/task-detail',
      'dashboard/teacher/home/task-detail',
      'dashboard/docker-management/environment',
      'dashboard/docker-management/nfs',
      'dashboard/docker-management/instance',
    ]

    if (wsc.every((r) => pathname.indexOf(r) === -1) && win.__podws__) {
      console.log('111')
      win.__podws__.close()
      win.__podws__ = null
    } else {
      console.log(win.__podws__, '222')
    }

    getRouteList()?.forEach((element: any) => {
      if (element.key && element.pathname === pathname) {
        key = element.key

        if (props.location.state) {
          setSessionStorageState(key, props.location.state)
        }
        const state = getSessionStorageState(key)
        if (state) {
          props.location.state = state
        } else {
          removeSessionStorageState()
        }
      }
      if (element.pathname !== '/login' && once && Cookies.get('token')) {
        once = false
        // gotDicts()
      }
    })
    if (!key) {
      routeList.forEach((item) => {
        if (pathname.includes(item.word)) {
          key = item.key
        }
      })
    }

    setSelectKeys(() => [key])
  }, [location])

  // const gotDicts = async () => {
  //   const { data } = await getDicts()
  //   if (data.errCode === 0) {
  //     if (data.data['View-Modal']) {
  //       const modal = data.data['View-Modal']
  //       sessionStorage.setItem('__view_modal__', modal.Key || 0)

  //     }
  //     if (data.data['Permission_Tree']) {
  //       sessionStorage.setItem('__Permission_Tree__', JSON.stringify(data.data.Permission_Tree))
  //     }
  //     if (data.data['Single_Login']) {
  //       sessionStorage.setItem('__Single_Login_Type__', data.data.Single_Login['Login-Type'])
  //     }

  //     dispatch({
  //       type: 'GET_DICTS',
  //       payload: data.data,
  //     })
  //   }
  // }

  // 导航栏点击
  const changeMenuFn = ({ key = '', keyPath = [''] }: any) => {
    setSelectKeys(() => keyPath)
    props.history.push({
      pathname: key,
    })
  }

  // 事件代理
  const handleClickUser = ({ key }: any) => {
    if (key === 'account') {
      hisatory.push('/dashboard/account/info')
    } else {
      setIsModalVisible(() => true)
    }
  }

  // 用户名下拉  onClick={handleClickUser}
  const menuUser = (
    <Menu onClick={handleClickUser} style={{ padding: 0, margin: 0 }}>
      <Menu.Item key="account" style={{ margin: 0, padding: 0 }}>
        <div
          style={{ display: 'flex', alignItems: 'center', height: '48px', justifyContent: 'center' }}
          className="dropMeme"
        >
          <img src={accountIcon} alt="icon" /> &nbsp; 账户信息
        </div>
      </Menu.Item>
      <Menu.Item key="logout" style={{ margin: 0, padding: 0 }}>
        <div
          style={{ display: 'flex', alignItems: 'center', height: '48px', justifyContent: 'center' }}
          className="dropMeme"
        >
          <img src={logoutIcon} alt="icon" /> &nbsp; 退出登录
        </div>
      </Menu.Item>
    </Menu>
  )

  const handleOk = async () => {
    setIsModalVisible(false)
    clearTimeout(win.__timerone__)
    if (Cookies.get('userType') === 'student' || Cookies.get('userType') === 'teacher') {
      // await deleteAllCase({
      //   userId: Cookies.get('userId'),
      // })
      // navigator.sendBeacon('/api/case/deleteAllCase', JSON.stringify({ userId: Cookies.get('userId') }))
      fetch('/api/case/deleteAllCase', {
        method: 'POST',
        body: JSON.stringify({ userId: Cookies.get('userId') }),
        keepalive: true,
      })
    }

    fetch('/api/users/logOut', {
      method: 'POST',
      body: JSON.stringify({ userId: Cookies.get('userId') }),
      keepalive: true,
    })

    Cookies.remove('token')
    Cookies.remove('userId')
    Cookies.remove('userCode')
    Cookies.remove('name')
    Cookies.remove('username')
    Cookies.remove('terminal')
    Cookies.remove('userType')
    Cookies.remove('encc')
    sessionStorage.clear()
    hisatory.replace('/login')
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div className={styles.basicLayout_page}>
      <header className={styles.basicLayout_header}>
        <img
          src={'/themes/' + (window as any).__Platform_Themes__ + '/logo.svg'}
          alt={''}
          className={styles.logo}
          onClick={() => {
            props.history.push('/dashboard/student/home')
          }}
        />
        <div className={styles.basicLayout_header_right}>
          {topAIJupyter ? (
            <div className={styles.ai_btn_drop}>
              <AIEnv
                change={(val: any) => {
                  aiEnvchange(val)
                }}
              />
            </div>
          ) : (
            ''
          )}
          <div className={styles.dashboard_user}>
            <Dropdown overlay={menuUser} placement="bottomCenter" overlayClassName="dropdown__container">
              <div className={styles.avatar_img__wrap}>
                <img src={touxiang} alt="" />
                <span className={styles.avatar_username}>{Cookies.get('name')}</span>
                <DownOutlined className={styles.avatar_img__arrow} />
              </div>
            </Dropdown>
          </div>
        </div>
      </header>
      <div className={styles.basicLayout_content}>
        {props.location.pathname.includes('/dashboard/student/home/task-detail') ||
        props.location.pathname.includes('/dashboard/teacher/home/task-detail') ? (
          ''
        ) : (
          <div className={styles.basicLayout_slider}>
            <Menu
              mode="inline"
              theme={Cookies.get('userType') === 'student' ? 'dark' : 'dark'}
              onClick={changeMenuFn}
              selectedKeys={selectKeys}
              defaultOpenKeys={openKeys}
              className={styles.basicLayout_side_menu}
            >
              {getMenu().length &&
                getMenu()?.map((element) => {
                  if (element.children) {
                    return (
                      <SubMenu key={element.pathname} title={element.name} icon={element.icon ? <element.icon /> : ''}>
                        {element.children &&
                          element.children.map((menuItem: any) => {
                            return (
                              <Menu.Item key={menuItem.pathname} icon={menuItem.icon ? <menuItem.icon /> : ''}>
                                {menuItem.name}
                              </Menu.Item>
                            )
                          })}
                      </SubMenu>
                    )
                  } else {
                    if (element.level === '1') {
                      return (
                        <Menu.Item
                          className="level1"
                          key={element.pathname}
                          icon={element.icon ? <element.icon /> : ''}
                        >
                          {element.name}
                        </Menu.Item>
                      )
                    } else {
                      return (
                        <Menu.Item key={element.pathname} icon={element.icon ? <element.icon /> : ''}>
                          {element.name}
                        </Menu.Item>
                      )
                    }
                  }
                })}
            </Menu>
            <div className={styles.basicLayout_menu_footer}>{sessionStorage.getItem('__Left_Menu_Footer__')}</div>
          </div>
        )}

        <Suspense
          fallback={
            <div className="layout-loading">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            {getRouteList().map((element) => {
              return <Route path={element.pathname} exact key={element.pathname} component={element.component} />
            })}
            <Route path="/dashboard" render={() => <Redirect to={getLink()} />} />
          </Switch>
        </Suspense>
      </div>
      <Modal
        title="退出登录"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="退出"
        okButtonProps={{ type: 'primary', danger: false, style: { width: 88 } }}
        cancelButtonProps={{ style: { width: 88 } }}
        className="layout__modal"
      >
        <p>确定要退出登录吗？</p>
      </Modal>
      {/* .....顶部按钮Ai终端环境..... */}
      {showAiTerm ? <Terminal></Terminal> : ''}
      {/* .....终端实例..... */}
      {showPfee ? (
        <Pfee
          close={() => {
            closePfee()
          }}
        ></Pfee>
      ) : (
        ''
      )}
    </div>
  )
}

export default BasicLayout
