/* eslint-disable no-fallthrough */
// Build a worker from an anonymous function body
const blobURL = URL.createObjectURL(
  new Blob(
    [
      '(',

      function () {
        const intervalIds = {}

        // 监听message 开始执行定时器或者销毁
        // eslint-disable-next-line no-restricted-globals
        self.onmessage = function onMsgFunc(e) {
          let intervalId = 0
          // eslint-disable-next-line default-case
          switch (e.data.command) {
            case 'interval:start': // 开启定时器
              intervalId = setInterval(function () {
                postMessage({
                  message: 'interval:tick',
                  id: e.data.id,
                })
              }, e.data.interval)

              postMessage({
                message: 'interval:started',
                id: e.data.id,
              })

              intervalIds[e.data.id] = intervalId
              break
            case 'interval:clear': // 销毁
              console.log(e.data.id, intervalIds, 'intervalIds')
              clearInterval(intervalIds[e.data.id])

              postMessage({
                message: 'interval:cleared',
                id: e.data.id,
              })

              delete intervalIds[e.data.id]
              break
            case 'timeout:start': // 开启定时器
              intervalId = setTimeout(function () {
                console.log('sww timout')
                postMessage({
                  message: 'timeout:tick',
                  id: e.data.id,
                })
              }, e.data.interval)

              postMessage({
                message: 'timeout:started',
                id: e.data.id,
              })

              intervalIds[e.data.id] = intervalId
              break
            case 'timeout:clear': // 销毁
              clearTimeout(intervalIds[e.data.id])

              postMessage({
                message: 'timeout:cleared',
                id: e.data.id,
              })

              delete intervalIds[e.data.id]
              break
          }
        }
      }.toString(),

      ')()',
    ],
    { type: 'application/javascript' }
  )
)

const worker = new Worker(blobURL)

URL.revokeObjectURL(blobURL)

class ScheduleWorker {
  constructor() {
    this.__id = 0
    this.__cb = {}
  }
  setInterval(cb, interval, context) {
    this.__id++
    this.__cb[this.__id] = {
      fn: cb,
      context: context,
    }
    worker.postMessage({
      command: 'interval:start',
      interval: interval,
      id: this.__id,
    })
    return this.__id
  }
  clearInterval(id) {
    worker.postMessage({ command: 'interval:clear', id: id })
  }
  setTimeout(cb, interval, context) {
    this.__id++
    this.__cb[this.__id] = {
      fn: cb,
      context: context,
    }
    worker.postMessage({
      command: 'timeout:start',
      interval: interval,
      id: this.__id,
    })
    return this.__id
  }
  clearIimout(id) {
    worker.postMessage({ command: 'timeout:clear', id: id })
  }
  message(e) {
    let cb = {}
    // eslint-disable-next-line default-case
    switch (e.data.message) {
      case 'interval:tick':
        cb = this.__cb[e.data.id]
        if (cb && cb.fn) {
          cb.fn.apply(cb.context)
          break
        }
      case 'interval:cleared':
        delete this.__cb[e.data.id]
        break
      case 'timeout:tick':
        cb = this.__cb[e.data.id]
        if (cb && cb.fn) {
          cb.fn.apply(cb.context)
          this.clearIimout(e.data.id)
          break
        }
      case 'timeout:cleared':
        delete this.__cb[e.data.id]
        break
    }
  }
}

const TMWorker = new ScheduleWorker()

worker.onmessage = TMWorker.message.bind(TMWorker)

export default TMWorker
