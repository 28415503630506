import axios from 'axios';
import Cookies from 'js-cookie';

const request = () => {
    let token = Cookies.get('token');
    // console.log('token :>> ', token);

    let fetch = axios.create({
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json; charset=utf-8',
            'authorization': `Bearer ${token}`,
            'Content-Security-Policy': 'upgrade-insecure-requests',
            'accept': 'text/html,application/octet-stream,application/json,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
        },
        onUploadProgress: p => { return 100 * (p.loaded / p.total) },
        onDownloadProgress: p => { return 100 * (p.loaded / p.total) }
    })

    return fetch;
}

export default request;