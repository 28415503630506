import React from 'react'
import Cookies from 'js-cookie'
import { BookOutlined, CodeOutlined, ProfileOutlined, FileDoneOutlined } from '@ant-design/icons'
import {
  MenuUnfoldOutlined,
  PicCenterOutlined,
  PicRightOutlined,
  DiffOutlined,
  FormOutlined,
  DeploymentUnitOutlined,
  FileZipOutlined
} from '@ant-design/icons'
import {
  ForkOutlined,
  AppstoreAddOutlined,
  FilePptOutlined,
  SettingOutlined,
  CarryOutOutlined,
  ApartmentOutlined,
  SlidersOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons'
import {
  AppstoreOutlined,
  UserSwitchOutlined,
  TeamOutlined,
  DeliveredProcedureOutlined,
  GoldOutlined,
  HddOutlined,
  LaptopOutlined,
  SaveOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'

//const TeacherManagement = React.lazy(() => import('@/pages/Dashboard/components/TeacherManagement'))
const StudentManagement = React.lazy(() => import('@/pages/Dashboard/components/StudentManagement'))
//const RoleAdd = React.lazy(() => import('@/common/CommonRoleAdd'))
//const RoleDetail = React.lazy(() => import('@/common/CommonRoleDetail/index'))
const LabelManagement = React.lazy(() => import('@/pages/Dashboard/components/system/LabelManagement/index'))
const AccountManagement = React.lazy(() => import('@/pages/Dashboard/components/AccountManagement'))
//const CourseGrading = React.lazy(() => import('@/pages/Dashboard/components/CourseManagement/CourseGrading'))
//const CourseView = React.lazy(() => import('@/pages/Dashboard/components/CourseManagement/CourseView'))
//const CourseDetail = React.lazy(() => import('@/pages/Dashboard/components/CourseManagement/CourseView/components/courseDetail'))
//const OperatePage = React.lazy(() => import('@/pages/Dashboard/components/CourseManagement/CourseView/components/operate'))
const ReportPage = React.lazy(() => import('@/pages/Dashboard/components/ReportPage/index'))
const ChangePassword = React.lazy(() => import('@/pages/ChangePassword/index'))
const TerminalComponent = React.lazy(() => import('@/pages/Dashboard/components/TerminalComponent/index'))
const ContractIDEComponent = React.lazy(() => import('@/pages/Dashboard/components/ContractIDEComponent/index'))
const ContractIDEBCOSComponent = React.lazy(() => import('@/pages/Dashboard/components/ContractIDEComponent/bcos'))

const ResourceDownload = React.lazy(() => import('@/pages/Dashboard/components/FileResource/download'))
// const AiTerminalComponent  = React.lazy(() => import('@/pages/Dashboard/components/AiTerminal/index'))

// 课程管理
const CourseManage = React.lazy(() => import('@/pages/Dashboard/components/CourseManage'))

// 资源管理
const ResouceView = React.lazy(() => import('@/pages/Dashboard/components/ResourceManagement/ResourceList'))
const ResourceDetail = React.lazy(() =>
  import('@/pages/Dashboard/components/ResourceManagement/ResourceList/ResourceDetail')
)
const Courseware = React.lazy(() => import('@/pages/Dashboard/components/ResourceManagement/Courseware'))
const Question = React.lazy(() => import('@/pages/Dashboard/components/ResourceManagement/Question'))

// 数据集管理
const DataView = React.lazy(() => import('@/pages/Dashboard/components/DataManagement'))

// 模型管理
const ModelView = React.lazy(() => import('@/pages/Dashboard/components/ModelManagement'))

// 模型评分包
const PackageView = React.lazy(() => import('@/pages/Dashboard/components/PackageManagement'))

// 智能设备管理
const IntelligentDevices = React.lazy(() => import('@/pages/Dashboard/components/IntelligentDevices/index'))

// 插件管理
const PluginManage = React.lazy(() => import('@/pages/Dashboard/components/PluginManage/plug'))

//终端环境管理
const ClusterManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Cluster'))
const MirrorManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Mirror'))
const SnapshotManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/nfs'))
const PoolManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Pool'))
const EnvironmentManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Environment'))
const InstanceManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Instance'))
const RecordManage = React.lazy(() => import('@/pages/Dashboard/components/DockerManageent/Record'))

// 教师管理
const TeacherManagementNew = React.lazy(() => import('@/pages/Dashboard/components/system/TeacherManagementNew'))
const TeacherHomeComponent = React.lazy(() => import('@/pages/Dashboard/components/teacher/index'))
const TeacherTaskList = React.lazy(() => import('@/pages/Dashboard/components/teacher/TaskList/index'))
const TeacherTaskDetail = React.lazy(() => import('@/pages/Dashboard/components/teacher/TaskDetail'))
const TeacherScoreReport = React.lazy(() => import('@/pages/Dashboard/components/teacher/ScoreReport/index'))

const HomeComponent = React.lazy(() => import('@/pages/Dashboard/components/HomeComponent/index'))
const TaskList = React.lazy(() => import('@/pages/Dashboard/components/HomeComponent/TaskList/index'))
const TaskDetail = React.lazy(() => import('@/pages/Dashboard/components/HomeComponent/TaskDetail'))
const TeachingTask = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/TeachingTask'))
const StudentsTask = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/StudentsTask'))
const StudentsTaskDetail = React.lazy(() =>
  import('@/pages/Dashboard/components/TeachingManagement/StudentsTask/detail')
)
const TaskScore = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/TaskScore'))
const TaskScoreDetail = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/TaskScore/detail'))
const TaskScoreScore = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/TaskScore/score'))
const Debriefing = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/Debriefing'))
const DebriefingDetail = React.lazy(() => import('@/pages/Dashboard/components/TeachingManagement/Debriefing/detail'))
const DebriefingCheck = React.lazy(() =>
  import('@/pages/Dashboard/components/TeachingManagement/Debriefing/checkdebrie')
)
const ScoreReport = React.lazy(() => import('@/pages/Dashboard/components/HomeComponent/ScoreReport/index'))
const StudentManageRun = React.lazy(() => import('@/pages/Dashboard/components/system/StudentManageRun'))

// 管理平台路由配置
export const getRouteList = () => {
  let routes = [
    // { pathname: "/dashboard/system/teacher-management", component: TeacherManagement, name: '教师管理' , type: ['super'], key: '/dashboard/system/teacher-management'},
    // { pathname: "/dashboard/system/teacher-management/add/:type", component: RoleAdd, name: '新增教师信息' , type: ['super'], key: '/dashboard/system/teacher-management' },
    // { pathname: "/dashboard/system/teacher-management/detail/:type/:id", component: RoleDetail, name: '教师信息详情', type: ['super'], key: '/dashboard/system/teacher-management' },

    // { pathname: "/dashboard/student-management", component: StudentManagement, name: '学生管理', type: ['super'], key: '/dashboard/student-management' },
    // { pathname: "/dashboard/student-management/add/:type", component: RoleAdd, name: '新增学生信息', type: ['super'], key: '/dashboard/student-management' },
    // { pathname: "/dashboard/student-management/detail/:type/:id", component: RoleDetail, name: '学生信息详情', type: ['super'], key: '/dashboard/student-management' },

    // { pathname: "/dashboard/course-management/view", component: CourseView, name: '课程查看', type: ['super', 'admin'], key: '/dashboard/course-management/view' },
    // { pathname: "/dashboard/course-management/view/detail/:title/:id", component: CourseDetail, name: '课程详情', type: ['super', 'admin'], key: '/dashboard/course-management/view' },
    // { pathname: "/dashboard/course-management/view/operate/:title/:id", component: OperatePage, name: '操作课程', type: ['super', 'admin'], key: '/dashboard/course-management/view' },
    // { pathname: "/dashboard/course-management/grade", component: CourseGrading, name: '课程打分' , type: ['super', 'admin'], key: '/dashboard/course-management/grade' },

    //个人中心
    {
      pathname: '/dashboard/account/info',
      component: AccountManagement,
      name: '账户管理',
      type: ['super', 'admin', 'teacher', 'student'],
      key: '/dashboard/account/info',
    },
    {
      pathname: '/dashboard/account/setting',
      component: StudentManagement,
      name: '密码重置',
      type: ['super', 'admin', 'teacher', 'student'],
      key: '/dashboard/account/setting',
    },
    {
      pathname: '/dashboard/account/change-password/:id',
      component: ChangePassword,
      name: '修改密码',
      type: ['super', 'admin', 'teacher', 'student'],
      key: '/dashboard/account/change-password',
    },

    {
      pathname: '/dashboard/resource/download',
      component: ResourceDownload,
      name: '资源下载',
      type: ['student', 'teacher'],
      key: '/dashboard/resource/download',
    },

    // 教师任务主页
    {
      pathname: '/dashboard/teacher/home',
      component: TeacherHomeComponent,
      name: '等完成任务',
      type: ['teacher'],
      key: '/dashboard/teacher/home',
    },
    {
      pathname: '/dashboard/teacher/complete',
      component: TeacherHomeComponent,
      name: '已完成任务',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },
    {
      pathname: '/dashboard/teacher/home/task/:taskId/:resourceId',
      component: TeacherTaskList,
      name: '任务章节',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },
    {
      pathname: '/dashboard/teacher/home/task-detail/:taskId/:resourceId/:chapterId/:stepId',
      component: TeacherTaskDetail,
      name: '实训中心',
      type: ['teacher'],
      key: '/dashboard/teacher/home',
    },
    {
      pathname: '/dashboard/teacher/home/score-report/:taskId',
      component: TeacherScoreReport,
      name: '评分报告',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },
    {
      pathname: '/dashboard/teacher/edit',
      component: TerminalComponent,
      name: '自由实验',
      type: ['teacher'],
      key: '/dashboard/teacher/edit',
    },
    {
      pathname: '/dashboard/teacher/complete/task/:taskId/:resourceId',
      component: TeacherTaskList,
      name: '任务章节',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },
    {
      pathname: '/dashboard/teacher/complete/task-detail/:taskId/:resourceId/:chapterId/:stepId',
      component: TeacherTaskDetail,
      name: '实训中心',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },
    {
      pathname: '/dashboard/teacher/complete/score-report/:taskId',
      component: TeacherScoreReport,
      name: '评分报告',
      type: ['teacher'],
      key: '/dashboard/teacher/complete',
    },

    // 学生主页
    {
      pathname: '/dashboard/student/home',
      component: HomeComponent,
      name: '等完成任务',
      type: ['student'],
      key: '/dashboard/student/home',
    },
    {
      pathname: '/dashboard/student/complete',
      component: HomeComponent,
      name: '已完成任务',
      type: ['student'],
      key: '/dashboard/student/complete',
    },
    {
      pathname: '/dashboard/student/home/task/:taskId/:resourceId',
      component: TaskList,
      name: '任务章节',
      type: ['student'],
      key: '/dashboard/student/home',
    },
    {
      pathname: '/dashboard/student/home/task-detail/:taskId/:resourceId/:chapterId/:stepId',
      component: TaskDetail,
      name: '实训中心',
      type: ['student'],
      key: '/dashboard/student/home',
    },
    {
      pathname: '/dashboard/student/home/score-report/:taskId',
      component: ScoreReport,
      name: '评分报告',
      type: ['student'],
      key: '/dashboard/student/home',
    },

    {
      pathname: '/dashboard/student/complete/task/:taskId/:resourceId',
      component: TaskList,
      name: '任务章节',
      type: ['student'],
      key: '/dashboard/student/complete',
    },
    {
      pathname: '/dashboard/student/complete/task-detail/:taskId/:resourceId/:chapterId/:stepId',
      component: TaskDetail,
      name: '实训中心',
      type: ['student'],
      key: '/dashboard/student/complete',
    },
    {
      pathname: '/dashboard/student/complete/score-report/:taskId',
      component: ScoreReport,
      name: '评分报告',
      type: ['student'],
      key: '/dashboard/student/complete',
    },

    {
      pathname: '/dashboard/student/contract',
      component: ContractIDEComponent,
      name: '智能合约IDE',
      type: ['student', 'teacher'],
      key: '/dashboard/student/contract',
    },
    {
      pathname: '/dashboard/student/bcos',
      component: ContractIDEBCOSComponent,
      name: '智能合约IDE(BCOS)',
      type: ['student', 'teacher'],
      key: '/dashboard/student/bcos',
    },
    {
      pathname: '/dashboard/IntelligentDevices/index',
      component: IntelligentDevices,
      name: '智能设备管理',
      type: ['student'],
      key: '/dashboard/IntelligentDevices/index',
    },
    {
      pathname: '/dashboard/student/edit',
      component: TerminalComponent,
      name: '自由实验',
      type: ['student'],
      key: '/dashboard/student/edit',
    },

    // {
    //   pathname: '/dashboard/AiTerminal',
    //   component: AiTerminalComponent,
    //   name: '自由实验',
    //   type: ['student'],
    //   key: '/dashboard/AiTerminal',
    // },

    // 课程管理
    {
      pathname: '/dashboard/course-manage',
      component: CourseManage,
      name: '课程管理',
      type: ['super', 'admin'],
      key: '/dashboard/course-manage',
    },
    {
      pathname: '/dashboard/course-management/grade/report/:id/:title',
      component: ReportPage,
      name: '实训报告',
      type: ['super', 'admin'],
      key: '/dashboard/course-management/grade',
    },

    // 教学管理
    {
      pathname: '/dashboard/teaching-management/teachingtask',
      component: TeachingTask,
      name: '教学任务',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/teachingtask',
    },
    {
      pathname: '/dashboard/teaching-management/studentstask',
      component: StudentsTask,
      name: window.__Platform_Modal_Config__['student'] + '任务',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/studentstask',
    },
    {
      pathname: '/dashboard/teaching-management/studentstask/detail/:id',
      component: StudentsTaskDetail,
      type: ['super', 'admin', 'teacher'],
      name: '进度详情',
      key: '/dashboard/teaching-management/studentstask',
    },
    {
      pathname: '/dashboard/teaching-management/taskscore',
      component: TaskScore,
      name: '任务评分',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/taskscore',
    },
    {
      pathname: '/dashboard/teaching-management/taskscore/detail/:id',
      component: TaskScoreDetail,
      name: '任务评分详情',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/taskscore',
    },
    {
      pathname: '/dashboard/teaching-management/taskscore/score/:id/:uid/:stuId',
      component: TaskScoreScore,
      name: '评分',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/taskscore',
    },
    {
      pathname: '/dashboard/teaching-management/debriefing',
      component: Debriefing,
      name: '任务报告',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/debriefing',
    },
    {
      pathname: '/dashboard/teaching-management/debriefing/detail/:id',
      component: DebriefingDetail,
      name: '报告详情',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/debriefing',
    },
    {
      pathname: '/dashboard/teaching-management/debriefing/checkbrie/:id/:uid',
      component: DebriefingCheck,
      name: '查看报告',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/teaching-management/debriefing',
    },

    // 资源管理
    {
      pathname: '/dashboard/resource-management/view',
      component: ResouceView,
      name: '资源列表',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/resource-management/view',
    },
    {
      pathname: '/dashboard/resource-management/detail',
      component: ResourceDetail,
      name: '资源详情',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/resource-management/detail',
    },
    {
      pathname: '/dashboard/resource-management/courseware',
      component: Courseware,
      name: window.__Platform_Modal_Config__['courseware'] + '管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/resource-management/courseware',
    },
    // { pathname: "/dashboard/resource-management/mirror", component: Mirror, name: '镜像管理', type: ['super', 'admin', 'teacher'], key: '/dashboard/resource-management/mirror' },
    {
      pathname: '/dashboard/resource-management/question',
      component: Question,
      name: '习题管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/resource-management/question',
    },
    // 数据集管理
    {
      pathname: '/dashboard/data-management/view',
      component: DataView,
      name: '数据集列表',
      type: ['super', 'admin', 'student', 'teacher'],
      key: '/dashboard/data-management/view',
    },
    // 模型管理
    {
      pathname: '/dashboard/modal-management/view',
      component: ModelView,
      name: '数据集列表',
      type: ['super', 'admin', 'student', 'teacher'],
      key: '/dashboard/modal-management/view',
    },
    {
      pathname: '/dashboard/package-management/view',
      component: PackageView,
      name: '模型评分包',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/package-management/view',
    },
    {
      pathname: '/dashboard/PluginManage/plug',
      component: PluginManage,
      name: '插件管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/PluginManage/plug',
    },

    //终端环境管理
    {
      pathname: '/dashboard/docker-management/cluster',
      component: ClusterManage,
      name: '集群管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/cluster',
    },
    {
      pathname: '/dashboard/docker-management/mirror',
      component: MirrorManage,
      name: '镜像列表',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/mirror',
    },
    {
      pathname: '/dashboard/docker-management/nfs',
      component: SnapshotManage,
      name: '持久卷管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/nfs',
    },
    {
      pathname: '/dashboard/docker-management/pool',
      component: PoolManage,
      name: 'IP池列表',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/pool',
    },
    {
      pathname: '/dashboard/docker-management/environment',
      component: EnvironmentManage,
      name: '实验环境',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/environment',
    },
    {
      pathname: '/dashboard/docker-management/instance',
      component: InstanceManage,
      name: '实例管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/instance',
    },
    {
      pathname: '/dashboard/docker-management/record',
      component: RecordManage,
      name: '实验记录',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/docker-management/record',
    },

    // 班级管理
    {
      pathname: '/dashboard/system/label-managemen',
      component: LabelManagement,
      name: window.__Platform_Modal_Config__['class'] + '管理',
      type: ['super', 'teacher'],
      key: '/dashboard/system/label-managemen',
    },
    // 教师管理
    {
      pathname: '/dashboard/system/teacher-management',
      component: TeacherManagementNew,
      name: window.__Platform_Modal_Config__['teacher'] + '管理',
      type: ['super'],
      key: '/dashboard/system/teacher-management',
    },
    // 学生管理
    {
      pathname: '/dashboard/system/student-managerun',
      component: StudentManageRun,
      name: window.__Platform_Modal_Config__['student'] + '管理',
      type: ['super', 'admin', 'teacher'],
      key: '/dashboard/system/student-managerun',
    },
    // 系统配置
    {
      pathname: '/dashboard/system/env',
      component: React.lazy(() => import('@/pages/Dashboard/components/system/env/index')),
      name: '系统配置',
      type: ['super', 'admin', 'teacher'],
      key: '/system/env',
    },
  ]

  if (Cookies.get('userType')) {
    routes = routes.filter((item) => item.type.filter((subItem) => subItem === Cookies.get('userType')).length > 0)
  }
  return routes
}

// 导航栏配置
export const getMenu = () => {
  let menu = []
  let menuList = [
    // { pathname: "/dashboard/student-management", name: '学生管理', type: ['super'] },

    {
      pathname: '/dashboard/student',
      name: '我的任务',
      type: ['student'],
      icon: MenuUnfoldOutlined,
      children: [
        { pathname: '/dashboard/student/home', name: '待完成任务', type: ['student'], icon: ProfileOutlined },
        { pathname: '/dashboard/student/complete', name: '已完成任务', type: ['student'], icon: FileDoneOutlined },
      ],
    },
    {
      pathname: '/dashboard/teacher',
      name: '我的任务',
      type: ['teacher'],
      icon: MenuUnfoldOutlined,
      children: [
        { pathname: '/dashboard/teacher/home', name: '待完成任务', type: ['teacher'], icon: ProfileOutlined },
        { pathname: '/dashboard/teacher/complete', name: '已完成任务', type: ['teacher'], icon: FileDoneOutlined },
      ],
    },
    {
      pathname: '/dashboard/resource/download',
      name: '资源下载',
      type: ['student', 'teacher'],
      icon: CloudDownloadOutlined,
    },
    { pathname: '/dashboard/student/contract', name: '智能合约IDE', type: ['student', 'teacher'], icon: BookOutlined },
    {
      pathname: '/dashboard/student/bcos',
      name: '智能合约IDE(BCOS)',
      type: ['student', 'teacher'],
      icon: BookOutlined,
    },
    { pathname: '/dashboard/IntelligentDevices/index', name: '智能设备管理', type: ['student'], icon: YoutubeOutlined },
    { pathname: '/dashboard/teacher/edit', name: '自由实验', type: ['teacher'], icon: CodeOutlined },
    { pathname: '/dashboard/student/edit', name: '自由实验', type: ['student'], icon: CodeOutlined },
    {
      pathname: '/dashboard/course-manage',
      name: window.__Platform_Modal_Config__['course'] + '管理',
      type: ['super', 'admin'],
      icon: ProfileOutlined,
    },
    // {
    //     pathname: "/dashboard/course-management",
    //     name: '实训课程管理',
    //     type: ['super', 'admin'],
    //     children: [
    //         { pathname: "/dashboard/course-management/view", name: '课程查看' },
    //         { pathname: "/dashboard/course-management/grade", name: '课程打分' },
    //     ]
    // },
    {
      pathname: '/dashboard/teaching-management',
      name: window.__Platform_Modal_Config__['teaching'] + '管理',
      type: ['super', 'admin', 'teacher'],
      icon: MenuUnfoldOutlined,
      children: [
        {
          pathname: '/dashboard/teaching-management/teachingtask',
          name: window.__Platform_Modal_Config__['teaching'] + '任务',
          icon: PicCenterOutlined,
        },
        {
          pathname: '/dashboard/teaching-management/studentstask',
          name: window.__Platform_Modal_Config__['student'] + '任务',
          icon: PicRightOutlined,
        },
        { pathname: '/dashboard/teaching-management/taskscore', name: '任务评分', icon: DiffOutlined },
        { pathname: '/dashboard/teaching-management/debriefing', name: '任务报告', icon: FormOutlined },
      ],
    },
    {
      pathname: '/dashboard/resource-management/view',
      name: '资源管理',
      type: ['super', 'admin'],
      icon: AppstoreAddOutlined,
    },
    {
      pathname: '/dashboard/data-management/view',
      name: '数据集管理',
      type: ['super', 'admin', 'student', 'teacher'],
      icon: SlidersOutlined,
    },
    {
      pathname: '/dashboard/modal-management/view',
      name: '模型管理',
      type: ['super', 'admin', 'student', 'teacher'],
      icon: DeploymentUnitOutlined,
    },
    {
      pathname: '/dashboard/package-management/view',
      name: '模型评分包',
      type: ['super', 'admin', 'teacher'],
      icon: FileZipOutlined,
    },
    {
      // 集群
      pathname: '/dashboard/docker-management',
      name: '终端环境管理',
      type: ['super', 'admin'],
      icon: HddOutlined,
      children: [
        { pathname: '/dashboard/docker-management/cluster', name: '集群管理', icon: DeliveredProcedureOutlined },
        { pathname: '/dashboard/docker-management/mirror', name: '镜像列表', icon: LaptopOutlined },
        { pathname: '/dashboard/docker-management/pool', name: 'IP池列表', icon: SaveOutlined },
        { pathname: '/dashboard/docker-management/environment', name: '实验环境', icon: GoldOutlined },
        { pathname: '/dashboard/docker-management/nfs', name: '持久卷管理', icon: ForkOutlined },
        { pathname: '/dashboard/docker-management/instance', name: '实例管理', icon: DatabaseOutlined },
        { pathname: '/dashboard/docker-management/record', name: '实验记录', icon: DashboardOutlined },
      ],
    },
    { pathname: '/dashboard/PluginManage/plug', type: ['super', 'admin'], name: '插件管理', icon: ApartmentOutlined },
    {
      pathname: '/dashboard/resource-management/courseware',
      name: window.__Platform_Modal_Config__['courseware'] + '管理',
      type: ['super', 'admin'],
      icon: FilePptOutlined,
    },
    {
      pathname: '/dashboard/resource-management/question',
      name: '习题管理',
      type: ['super', 'admin'],
      icon: CarryOutOutlined,
    },
    {
      pathname: '/dashboard/system',
      name: '系统管理',
      type: ['super', 'admin', 'teacher'],
      icon: SettingOutlined,
      children: [
        {
          pathname: '/dashboard/system/student-managerun',
          name: window.__Platform_Modal_Config__['student'] + '管理',
          type: ['super', 'admin', 'teacher'],
          icon: TeamOutlined,
        },
        {
          pathname: '/dashboard/system/teacher-management',
          name: window.__Platform_Modal_Config__['teacher'] + '管理',
          type: ['super'],
          icon: UserSwitchOutlined,
        },
        {
          pathname: '/dashboard/system/label-managemen',
          name: window.__Platform_Modal_Config__['class'] + '管理',
          type: ['super', 'teacher'],
          icon: AppstoreOutlined,
        },
        { pathname: '/dashboard/system/env', name: '数字字典', type: ['super', 'admin'], icon: BookOutlined },
      ],
    },
  ]
  const Permission_Tree = (level) => {
    let a = sessionStorage.getItem('__Permission_Tree__')
    if (a) {
      a = JSON.parse(a)
      const o = Object.keys(a).map((v) => {
        const urls = v
          .toLowerCase()
          .replace(/_/g, '/')
          .split(',')
          .map((v) => {
            return '/' + v
          })
        return {
          pathname: urls,
          show: a[v] * 1,
        }
      })
     
      const level2 = o.filter((r) => r.pathname.includes(level.pathname.toLowerCase()))
      const show = level2 && level2.length > 0 ? level2[0].show : 1
      return show
    } else {
      return 1
    }
  }
  if (Cookies.get('userType')) {
    let userType = Cookies.get('userType')
    menuList.forEach((m) => {
      if (m.type.includes(userType) && Permission_Tree(m)) {
        if (m.children) {
          m.children = m.children.filter((c) => {
            c.level = '2'
            return (!c.type && Permission_Tree(c)) || (c.type && c.type.includes(userType) && Permission_Tree(c))
          })
        }
        m.level = '1'
        menu.push(m)
      }
    })
  }
  return menu
}
