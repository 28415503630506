import axios from '@/api/axios'

// 获取环境变量
export const getEnv = (params) => {
    return axios({
        method: 'GET',
        url: '/api/env',
        params,
    })
}

// 获取环境变量
export const getEnvParant = (params) => {
    return axios({
        method: 'GET',
        url: '/api/env/parent',
        params,
    })
}

// 获取树形环境变量
export const getTreeData = (params) => {
    return axios({
        method: 'GET',
        url: '/api/env/getTreeData',
        params,
    })
}


// 获取环境变量
export const getDicts = (params) => {
    return axios({
        method: 'GET',
        url: '/api/env/getDicts',
        params,
    })
}


// 创建环境变量
export const createEnv = (data) => {
    return axios({
        method: 'POST',
        url: '/api/env',
        data,
    })
}

// 创建环境变量
export const updateEnv = (data) => {
    return axios({
        method: 'POST',
        url: '/api/env/update',
        data,
    })
}

// 删除环境变量
export const deleteted = (data) => {
    return axios({
        method: 'POST',
        url: '/api/env/delete',
        data,
    })
}

