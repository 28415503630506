import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import resourceReducer from '@/pages/Dashboard/components/ResourceManagement/ResourceList/reducer'
import globalModalReducer from '@/common/CommonModal/reducer'
import Global from './global'


const totalReducers = {
  resourceReducer,
  globalModalReducer,
  Global,
  app: () => ({}),
}

const store = createStore(
  combineReducers(totalReducers),
  composeWithDevTools(applyMiddleware())
)

export default store
