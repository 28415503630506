import Cookies from 'js-cookie'
import Instance from '@/pages/Dashboard/components/DockerManageent/Instance'
import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
const Pfee = (props) => {
  let [userType, ] = useState(Cookies.get('userType') === 'student' ? 1 : 2);
  const close = () => {
    props.close()
  }
  return (
    <div className="case-online">
      <span className='close' onClick={() => { close() }}><CloseOutlined /></span>
      <Instance type={userType} close={() => { close() }}></Instance>
    </div>
  )
}

export default Pfee
