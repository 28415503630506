const competition = {
  course: '任务',
  teaching: '竞赛',
  student: '选手',
  courseware: '文件',
  teacher: '管理员',
  start: '开始竞赛',
  ended: '已竞赛',
  class: '场次',
}

const study = {
  course: '课程',
  teaching: '教学',
  student: '学生',
  teacher: '教师',
  courseware: '课件',
  start: '开始学习',
  ended: '已学习',
  class: '班级',
}

const exam = {
  course: '课程',
  teaching: '教学',
  student: '学生',
  teacher: '教师',
  courseware: '课件',
  start: '开始考试',
  ended: '已考试',
  class: '班级',
}

module.exports = {
  1: study,
  2: competition,
  3: exam
}
