const actionType = {
  CHANGE_ADDRESOURCESUCCESS: 'CHANGE_ADDRESOURCESUCCESS', // 是否添加成功
  CHANGE_ADD_OR_EDIT_MESSAGE: 'CHANGE_ADD_OR_EDIT_MESSAGE', // 添加/编辑 => 资源
  CHANGE_ADD_OR_EDIT_CHAPTER: 'CHANGE_ADD_OR_EDIT_CHAPTER', // 添加/编辑 => 章节
  CHANGE_ADD_OR_EDIT_STEP: 'CHANGE_ADD_OR_EDIT_STEP', // 添加/编辑 => 步骤

  EDIT_MESSAGE: 'EDIT_MESSAGE', // button ------------编辑 资源信息
  EDIT_CHAPTER: 'EDIT_CHAPTER', // button ------------编辑 资源章节
  CHANGE_EDIT_TYPE: 'CHANGE_EDIT_TYPE', // button ----改变button编辑类型，即当前编辑 信息||章节

  EDIT_TREE_CHAPTER: 'EDIT_TREE_CHAPTER', // tree --------编辑 新章节
  EDIT_TREE_STEP: 'EDIT_TREE_STEP', // tree --------------编辑 新步骤
  CHANGE_EDIT_TREE_TYPE: 'CHANGE_EDIT_TREE_TYPE', // 改变tree类型

  ADD_TREE_CHAPTER: 'ADD_TREE_CHAPTER', // 添加章节
  Add_TREE_STEP: 'Add_TREE_STEP', // 添加步骤
  CHANGE_TREE_DATA: 'CHANGE_TREE_DATA', // 修改整个tree的数据，主要用在form改变后去设置tree

  SET_MESSAGE_FORM_DATA: 'SET_MESSAGE_FORM_DATA', // messageForm => 设置资源form数据
  SET_CHAPTER_FORM_DATA: 'SET_CHAPTER_FORM_DATA', // chapterForm => 设置章节form数据
  SET_STEP_FORM_DATA: 'SET_STEP_FORM_DATA', // stepForm => 设置步骤form数据
  SET_CURRENT_FILE_DATA: 'SET_CURRENT_FILE_DATA', // 上传数据保存，主要不能用fileList的polyfill

  SET_CURRENT_MESSAGEID: 'SET_CURRENT_MESSAGEID',
  SET_CURRENT_CHAPTERID: 'SET_CURRENT_CHAPTERID',
  SET_CURRENT_STEPID: 'SET_CURRENT_STEPID',
}

export default actionType
