import React from 'react'
import { Table, Input, Select, Modal, Tooltip, Checkbox, Button, message } from 'antd'
import CommonTitle from '@/common/CommonTitle'
import './index.less'
import { getCase, updateCase, deleteCase } from '@/api/case/index'
import { createLog } from '@/api/log/index'
import {
  StopOutlined,
  CheckCircleOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  SyncOutlined,
  UserOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import Cookies from 'js-cookie'
import WSY from '@/utils/wsy'
window.__WSY = window.__WSY ? window.__WSY : WSY

const { Option } = Select
const { confirm } = Modal
const { Search } = Input

class Instance extends React.Component {
  state = {
    rows: [],
    dataSource: [],
    enable: true,
    pagination: {},
    searchValue: '',
    termVisible: false,
    terminalLoading: false,
    termUrl: '',
    checked: true,
  }
  constructor(props) {
    super(props)
    this.titleConf = {
      pageTitle: '实例管理',
      icon: <DatabaseOutlined />,
      iconIsBack: false,
      _props: props,
      isShowLoading: false,
    }
    this.paper = {
      pageNum: 1,
      pageSize: 50,
    }
    this.type = props.type
    this.state.checked = this.type === 1 ? false : true
    this.params = {}

    this.props = props

    // url = 'ws://' + window.location.hostname + ':9090/ws/podWatcher'
    // window.__podws__ = new WebSocket(url)
  }
  componentDidMount() {
    this.getList()
  }
  async getList() {
    const obj = Object.assign({}, this.paper, this.params, this.type === 1 ? { user: Cookies.get('userId') } : {})
    const res = await getCase(obj)

    if (res.data.errCode === 0) {
      this.setState({
        dataSource: res.data.data.list,
      })
      this.setState({
        pagination: {
          position: ['bottomLeft'],
          total: res.data.data.total,
          showSizeChanger: true,
          showQuickJumper: true,
          current: this.paper.pageNum,
          showTotal: (total) => `共${total}条`,
          pageSize: 50,
          onChange: (page, pageSize) => {
            this.paper = {
              pageNum: page,
              pageSize: pageSize,
            }
            this.getList()
          },
        },
      })
    }
  }
  formatDuring(mss) {
    let days = parseInt(mss / (1000 * 60 * 60 * 24))
    let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
    days = days > 0 ? days + ' 天 ' : ''
    hours = hours > 0 ? hours + ' 小时 ' : ''
    minutes = minutes > 0 ? minutes + ' 分钟' : '0 分钟'
    return days + hours + minutes
  }
  async deleteCase(val) {
    await deleteCase(val)
    this.getList()
  }
  setLoadingInfo(msg) {
    this.setState({
      loadingInfo: msg,
    })
  }
  WatchPodStatus() {
    window.__WSY.createdIntanceTerminal = (row) => {
      try {
        const url = document.location.protocol + '//' + row.ip + ':' + row.port
        this.setState({
          termUrl: url,
          terminalLoading: false,
        })
        const ifm = document.querySelector('.term-frame')
        if (ifm && ifm.src.indexOf(url) === -1) {
          setTimeout(() => {
            ifm.src = url
          }, 4000)
        }
      } catch (error) {}
    }
  }
  dispatchWatch(port, ip, row, del) {
    const o = JSON.stringify({
      userId: row.user._id,
      imageId: row.environment._id,
      evid: row.environment.uid,
      phone: row.user.username,
      port,
      ip: ip,
      status: del ? 'delete' : '',
      pvc: this.state.checked ? 1 : 0,
    })
    console.log(o, '-----9------')
    window.__WSY.send(o)
    this.WatchPodStatus()
  }
  // async createK8S(row) {
  //   this.setState({
  //     terminalLoading: true,
  //   })
  //   const res = await createK8S({
  //     taskId: row.task._id,
  //     image: row.environment._id,
  //     userId: row.user._id,
  //     phone: row.user.username,
  //   })
  //   if (res.data.errCode === 0 && res.data.data) {
  //     if (res.data.data === 0) {
  //       this.setLoadingInfo(res.data.message)
  //       return
  //     }
  //     if (res.data.data.enable === 0) {
  //       this.setLoadingInfo('您的实验环境已被禁用，请联系管理员！')
  //       return
  //     }
  //     const port = res.data.data.ssh ? res.data.data.ssh.split('/')[2] : '18888'
  //     const ip = res.data.data.Pip ? res.data.data.Pip : res.data.data.ip
  //     this.dispatchWatch(port, ip, row)
  //   } else {
  //     console.log('ceww', res.data.message)
  //   }
  // }
  term(row) {
    this.row = row
    this.row.step = 0
    this.row.timer = null
    let IP = row.Pip ? row.Pip : row.ip
    let hs = '//' + IP + ':' + (row.port || 18888)
    let ap = IP.split(':')
    if (ap.length > 1) {
      hs = '//' + ap[0] + ':18' + ap[1]
    }
    const url = document.location.protocol + hs
    console.log(url, '----url----')
    this.setState({
      termVisible: true,
      termUrl: url,
      terminalLoading: false,
    })
    // const ifm = document.querySelector('.term-frame')
    // ifm.src = url
    // this.createK8S(row)
  }
  handleTermCancel() {
    this.setState({
      termVisible: false,
      termUrl: '',
    })
    this.row.step = 0
    clearTimeout(this.row.__timerone__)
  }
  changeParmas(key, val) {
    const o = {}
    o[key] = val
    this.setState({
      status: val,
    })
    this.params = Object.assign({}, this.params, o)
    this.doSearch()
  }
  resetSearch() {
    this.params = {}
    this.setState({
      searchValue: '',
      status: '',
    })
    this.paper = {
      pageNum: 1,
      pageSize: 10,
    }
    this.getList()
  }
  doSearch(e) {
    if (e && e.target) {
      this.params.ip = e.target.value
      this.setState({
        searchValue: e.target.value,
      })
    }
    this.paper = {
      pageNum: 1,
      pageSize: 50,
    }
    this.getList()
  }
  async createLog(row) {
    // 创建实验释放记录
    const duration = moment(new Date()).valueOf() - moment(row.createAt).valueOf()
    const obj = {
      userId: row.user._id,
      taskId: row.task._id,
      image: row.environment?._id,
      status: 1,
      ip: row.ip,
      duration: duration,
    }
    obj.status = 2
    obj.offType = 0
    await createLog(obj)
  }
  async doEnable(row) {
    // 设置启用，禁用状态
    const val = Number(!row.enable)
    this.setState({
      enable: val,
    })
    await updateCase({
      _id: row._id,
      enable: val,
    })
    this.getList()
  }
  changePVC(e) {
    console.log(e, 'cvav')
    this.setState({
      checked: e.target.checked,
    })
  }
  onSelectChange(ids) {
    let rows = []
    this.state.dataSource.forEach((row) => {
      if (ids.includes(row._id)) {
        rows = rows.concat(row)
      }
    })
    this.setState({
      rows: rows,
    })
  }
  async patchFree() {
    if (this.state.rows.length < 1) {
      message.warning('请选择实例！')
      return
    }
    confirm({
      wrapClassName: 'hight-model',
      zIndex: 100000000000,
      content: (
        <div>
          <p>请确认是否释放实例？</p>
          {this.type === 1 ? (
            ''
          ) : (
            <Checkbox defaultChecked={true} onChange={(e) => this.changePVC(e)} style={{ margin: '15px 0 0 40px' }}>
              删除PVC（会删除持久化数据！）
            </Checkbox>
          )}
        </div>
      ),
      onOk: async () => {
        this.state.rows.forEach((row) => {
          this.deleteCase({
            ip: row.ip,
            userId: row.user._id,
            image: row.environment && row.environment._id ? row.environment._id : '',
            evid: row.environment && row.environment._id ? row.environment.uid : '',
            _id: row._id,
            pvc: this.state.checked,
            phone: row.user.username,
          })
          if (this.props.close) {
            this.props.close()
          }
        })
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  free(row) {
    confirm({
      wrapClassName: 'hight-model',
      zIndex: 100000000000,
      content: (
        <div>
          <p>请确认是否释放该实例？</p>

          {this.type === 1 ? (
            ''
          ) : (
            <Checkbox defaultChecked={true} onChange={(e) => this.changePVC(e)} style={{ margin: '15px 0 0 40px' }}>
              删除PVC（会删除持久化数据！）
            </Checkbox>
          )}
        </div>
      ),
      onOk: async () => {
        if (row?.environment && row?.environment?._id) {
          this.createLog(row)
        }
        this.deleteCase({
          ip: row.ip,
          userId: row.user._id,
          image: row.environment && row.environment._id ? row.environment._id : '',
          evid: row.environment && row.environment._id ? row.environment.uid : '',
          _id: row._id,
          pvc: this.state.checked,
          phone: row.user.username,
        })
        const port = row.ssh ? row.ssh.split('/')[2] : '18888'
        const ip = row.ip
        this.dispatchWatch(port, ip, row, true)
        if (this.props.close) {
          this.props.close()
        }
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }
  render() {
    const columns = [
      {
        title: '姓名',
        render: (text, row) => <span>{row.user ? row.user.name : ''}</span>,
        width: 120,
      },
      {
        title: '任务',
        render: (text, row) => <span>{row.task && row.task.name}</span>,
        width: 140,
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
        width: 140,
      },
      {
        title: '状态',
        align: 'center',
        width: 70,
        render: (
          text,
          row // 1: '分配' 2: '运行中'  3: '释放'
        ) => (
          <span>
            {row.status === 2 ? (
              <Tooltip placement="top" title={'运行中'}>
                <PlayCircleOutlined />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={'分配'}>
                <CheckCircleOutlined />
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        title: '启动时间',
        dataIndex: 'createAt',
        key: 'createAt',
        render: (text, row) => <span>{moment(row.createAt).format('YYYY-MM-DD HH:mm:ss')}</span>,
      },
      {
        title: '时长',
        render: (text, row) => (
          <span>{this.formatDuring(moment(new Date()).valueOf() - moment(row.createAt).valueOf())}</span>
        ),
      },
      // {
      //     title: '环境变量',
      //     dataIndex: '_id',
      //     key: '_id',
      //     width: 200,
      //     render: (text, row) => (
      //         <span style={{ display: 'block', 'word-break': 'break-all' }}>{row._id}</span>
      //     ),
      // },
      {
        title: '镜像',
        render: (text, row) => <span>{row.environment && row.environment.name}</span>,
        width: 160,
      },
      {
        title: 'ssh信息',
        render: (text, row) => <span>{row.environment && row.environment.image && row.environment.image.detail}</span>,
      },
      {
        title: '版本',
        render: (text, row) => <span>{row.environment && row.environment.version}</span>,
        width: 80,
      },
      {
        title: '操作',
        width: 120,
        render: (text, record) => (
          <div key={record._id}>
            {this.type === 1 ? (
              <span style={{ color: '#1890ff', marginRight: '0', cursor: 'pointer' }} onClick={() => this.free(record)}>
                <Tooltip placement="top" title={'释放'}>
                  {' '}
                  <LogoutOutlined />{' '}
                </Tooltip>
              </span>
            ) : (
              <>
                <span
                  className="render_right_edit"
                  style={{ color: '#1890ff', cursor: 'pointer' }}
                  onClick={() => this.doEnable(record)}
                >
                  {record.enable ? (
                    <Tooltip placement="top" title={'禁用'}>
                      <span>
                        <StopOutlined />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={'启用'}>
                      <span>
                        <CheckCircleOutlined style={{ marginRight: '3px' }} />
                      </span>
                    </Tooltip>
                  )}
                </span>

                <span
                  style={{ color: '#1890ff', marginRight: '0', cursor: 'pointer' }}
                  onClick={() => this.free(record)}
                >
                  <Tooltip placement="top" title={'释放'}>
                    {' '}
                    <LogoutOutlined />{' '}
                  </Tooltip>
                </span>
                <span className="render_right_edit" onClick={() => this.term(record)}>
                  <Tooltip placement="top" title={'登录'}>
                    {' '}
                    <UserOutlined />{' '}
                  </Tooltip>
                </span>
              </>
            )}
          </div>
        ),
      },
    ]

    return (
      <div className="instance-manage">
        <CommonTitle {...this.titleConf} />
        <div className="search">
          <Button
            type="primary"
            onClick={() => {
              this.patchFree()
            }}
          >
            批量删除
          </Button>
          <div className="header__searchs">
            <div className="gl">
              <div className="ac">
                <Select
                  placeholder="状态"
                  value={this.state.status}
                  allowClear
                  onChange={(val) => {
                    this.changeParmas('status', val)
                  }}
                  style={{ width: '150px', marginRight: '10px' }}
                >
                  <Option value="1">分配</Option>
                  <Option value="2">运行中</Option>
                  <Option value="3">释放</Option>
                </Select>
              </div>
            </div>
            <Search
              placeholder="请输入IP关键字进行搜索"
              value={this.state.searchValue}
              onSearch={(val) => this.doSearch(val)}
              onChange={(val) => this.doSearch(val)}
              enterButton
              style={{ width: 260 }}
            />
            <span className="header_searchs_reset" onClick={() => this.resetSearch()}>
              <SyncOutlined /> 重置
            </span>
          </div>
        </div>
        <div className="table">
          <Table
            dataSource={this.state.dataSource}
            rowKey="_id"
            rowSelection={{
              onChange: (val) => {
                this.onSelectChange(val)
              },
            }}
            columns={columns}
            pagination={this.state.pagination}
            bordered={true}
          />
        </div>

        {/* .....实验环境..... */}
        <Modal
          width={900}
          title="实验环境"
          visible={this.state.termVisible}
          footer={false}
          onCancel={() => {
            this.handleTermCancel()
          }}
        >
          <div className="term-org">
            {this.state.terminalLoading ? (
              <div className="pre-loading">
                {this.state.loadingInfo ? '' : <div className="circle"></div>}
                <div className="msg">
                  {this.state.loadingInfo ? this.state.loadingInfo : '镜像安装中，请耐心等待...'}
                </div>
              </div>
            ) : (
              ''
            )}
            <iframe title="''" src={this.state.termUrl} className="term-frame" frameBorder="0"></iframe>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Instance
