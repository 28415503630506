import React from 'react'
import { Spin } from 'antd'
import styles from './index.module.less'
import IconFont from '@/common/IconFont'

const CommonTitle = (props) => {
  // 点击icon
  const clickIcon = () => {
    const { _props } = props
    if (!props.iconIsBack) {
      return false
    }
    _props && _props.history.go(-1)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {typeof (props.icon) === 'string' ?
          <img
            className={props.iconIsBack ? styles.arrowBack : ''}
            style={{
              cursor: props.iconIsBack ? 'pointer' : 'default',
              display: props.iconIsBack ? 'none' : 'block',
            }}
            onClick={() => {
              clickIcon()
            }}
            src={props.icon}
            alt=""
          /> : <span style={{position: 'relative', top: '2px'}}>{props.icon}</span>
        }

        <IconFont
          type="icon-back"
          className={props.iconIsBack ? styles.arrowBack : ''}
          style={{
            display: props.iconIsBack ? 'block' : 'none',
            boxSizing: 'border-box',
            height: '22px',
            width: '22px',
            fontSize: '17px',
            lineHeight: '17px',
            margin: 0,
            paddingTop: '3px',
          }}
          onClick={() => {
            clickIcon()
          }}
        />
        <span>{props.pageTitle}</span>
      </div>

      <div className={styles.operate}>
        {props.isShowLoading && !props.isShowChildren ? <Spin /> : ''}
        {!props.isShowLoading && props.isShowChildren ? props.children : ''}
      </div>
    </div>
  )
}

export default CommonTitle
