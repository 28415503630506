import React from 'react'
import { Modal, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import actionType from './constant'

const CommonFormMModal = (props) => {
  console.log('props :>> ', props.chidren);
  const {
    globalModalData: { title = '', content = '', footer = [] },
    globalModalVisible,
  } = useSelector((state) => state.globalModalReducer)

  const dispatch = useDispatch()

  const renderFooter = () =>
    footer.length
      ? footer.map(({ name = '', fn = () => {}, type = 'default', loading = false }, index) => {
          return (
            <Button key={index} onClick={fn} type={type} loading={loading}>
              {name}
            </Button>
          )
        })
      : null

  const onCancel = () => {
    dispatch({
      type: actionType.CHANGE_GLOBAL_MODAL_VISIBLE,
      payload: false,
    })
  }

  return (
    <Modal title={title} visible={globalModalVisible} footer={renderFooter()} onCancel={onCancel}>
      {props.children ? props.children : content}
    </Modal>
  )
}

export default CommonFormMModal
