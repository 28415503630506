import axios from '@/api/axios'

// 获取环境变量
export const get = (params) => {
    return axios({
        method: 'get',
        url: '/api/environment',
        params
    })
}

export const getEnvironmentByIds = (params) => {
    return axios({
        method: 'get',
        url: '/api/environment/getEnvironmentByIds',
        params
    })
}

// 获取环境变量
export const getEVI = (params) => {
    return axios({
        method: 'GET',
        url: '/api/environment/getEVI',
        params,
    })
}




// 创建实验环境
export const create = (data) => {
    return axios({
        method: 'POST',
        url: '/api/environment',
        data,
    })
}

// 更新实验环境
export const update = (data) => {
    return axios({
        method: 'POST',
        url: '/api/environment/update',
        data,
    })
}

// 设置快照状态
export const updateSnapshotStatus = (data) => {
    return axios({
        method: 'POST',
        url: '/api/environment/updateSnapshotStatus',
        data,
    })
}

// 删除实验环境
export const del = (data) => {
    return axios({
        method: 'POST',
        url: '/api/environment/delete',
        data,
    })
}

// 获取环境变量
export const hasUsed = (params) => {
    return axios({
        method: 'GET',
        url: '/api/environment/hasUsed',
        params,
    })
}

