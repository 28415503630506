import React, { useEffect } from 'react'
import { Menu, Dropdown } from 'antd'

import './index.less'
// import { getJupyterURL } from '@/api/plug'
// import Cookies from 'js-cookie'

const AIEnv = (props) => {
  // const [jupyterURL, setJupyterURL] = useState('')
  // const [hasDevice, setHasDevice] = useState('')

  // const getHasDevice = () => {
  //   let devices = sessionStorage.getItem('__Permission_Tree__')
  //   if (devices) {
  //     devices = JSON.parse(devices)
  //     if(devices.Dashboard_IntelligentDevices_Index) {
  //       setHasDevice(devices.Dashboard_IntelligentDevices_Index * 1)
  //     }
  //   }
  // }

  // const getJPTURL = async () => {
  //   const { data } = await getJupyterURL({
  //     userId: Cookies.get('userId'),
  //   })
  //   if (data.errCode === 0) {
  //     const url = data.data
  //     setJupyterURL(url)
  //   }
  // }

  const goJuoyter = (val) => {
    if (val.key === 'JupyterHome') {
      // window.open(jupyterURL +'&next=/hub/home')
      props.change(1)
    } else if (val.key === 'IntelligentDevices') {
      // window.open('//' + window.location.host + '/dashboard/IntelligentDevices/index')
    } else {
      // window.open(jupyterURL)
      props.change(2)
    }
  }

  useEffect(() => {
    // getJPTURL()
    // getHasDevice()
  }, [])

  // AI 下拉
  const menuLink = (
    <Menu onClick={goJuoyter} style={{ padding: 0, margin: 0 }}>
      <Menu.Item key="JupyterLab" style={{ margin: 0, padding: 0 }}>
        <div
          style={{ display: 'flex', alignItems: 'center', height: '48px', justifyContent: 'center' }}
          className="dropMeme"
        >
          <span>JupyterLab</span>
        </div>
      </Menu.Item>
      <Menu.Item key="JupyterHome" style={{ margin: 0, padding: 0 }}>
        <div
          style={{ display: 'flex', alignItems: 'center', height: '48px', justifyContent: 'center' }}
          className="dropMeme"
        >
          <span>配置管理面板</span>
        </div>
      </Menu.Item>
      {/* {
        Cookies.get('userType') === 'student' && hasDevice * 1 === 1 ?  <Menu.Item key="IntelligentDevices"><span style={{ display: 'flex', alignItems: 'center', height: '48px', justifyContent: 'center' }}>智能设备实验环境</span></Menu.Item> : ''
      } */}
    </Menu>
  )

  const render = () => {
    return (
      <>
        
        <Dropdown overlay={menuLink} placement="bottomCenter" overlayClassName="dropdown__container">
          <div
            className="AI-btn"
            onClick={() => {
              goJuoyter('JupyterHome')
            }}
          >
            AI开发环境
          </div>
        </Dropdown>
      </>
    )
  }
  return <div className="AI_btn_drop">{render()}</div>
}

export default AIEnv
