const url = 'ws://' + window.location.hostname + ':9090/ws/podWatcher'

const poW = new WebSocket(url)
poW.clearTerminal = () => {}
poW.createdTerminal = () => {}
poW.clearTerminalService = () => {}
poW.clearTerminalEnd = () => {}

poW.createdIntanceTerminal = () => {}


poW.key = '';
poW.A = {}
poW.B = {}
poW.C = {}
poW.D = {}

poW.onmessage = (evt) => {
  if (evt.data === 'open') return
  const wsobj = JSON.parse(evt.data)
  const obj = wsobj['map']
  const name = wsobj['id']

  console.log(wsobj[name], wsobj, 'asdas')

  if (obj.object.kind === 'Pod' && obj.object.metadata.name.indexOf(name) === -1) {
    return
  }

  if (obj.object.kind === 'Service' && obj.object.metadata.name.indexOf(name) === -1) {
    return
  }

  if (obj.object.kind === 'PersistentVolume' && !obj.object.spec.claimRef) {
    return
  }

  if (
    obj.object.kind === 'PersistentVolume' &&
    obj.object.spec.claimRef &&
    obj.object.spec.claimRef.name.indexOf(name) === -1
  ) {
    return
  }

  console.log(obj, wsobj[name], window.location.href, '---obj.object---')

  if (wsobj[name]?.status && wsobj[name]?.status === 'delete') {
    console.log('----clearTerminal----')
    poW.clearTerminal()
  }


  if (
    obj.object.kind === 'Pod' &&
    obj.object.status.phase === 'Running' &&
    !wsobj[name].delete &&
    wsobj[name].status !== 'delete'
  ) {
    const nm = 'vm' + wsobj[name].phone + '-' + wsobj[name].evid
    if (obj.object.metadata.name.indexOf(nm) > -1) {
      console.log('----Runnig----')
      poW.createdTerminal(wsobj[name])
      poW.createdIntanceTerminal(wsobj[name])
    }
  } else if (
    obj.object.kind === 'Service' &&
    obj.type === 'DELETED' &&
    wsobj[name].delete &&
    wsobj[name].type === 1 &&
    obj.object.metadata.name.indexOf(name) > -1
  ) {
    console.log('----delete Service----')
    poW.clearTerminalService()
  } else if (
    wsobj[name].pvc !== 1 &&
    wsobj[name].status &&
    wsobj[name].status === 'delete' &&
    obj.type === 'DELETED' &&
    obj.object.kind === 'Pod'
  ) {
    // pod删除完成
    console.log('----------pod释放完成------------')
    poW.clearTerminalEnd()
  } else if (
    obj.type === 'DELETED' &&
    wsobj[name].pvc === 1 &&
    obj.object.kind === 'PersistentVolume' &&
    obj.object.status.phase === 'Released'
  ) {
    // pcv删除完成
    console.log('----------pvc释放完成------------')
    poW.clearTerminalEnd()
  }
}

export default poW;