import React, { useState, useEffect } from 'react'
import { createK8S, excludeByUserId, getUsedCase } from '@/api/case'
import { get } from '@/api/environment'
import Cookies from 'js-cookie'

import './index.less'
import { InfoCircleOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons'

import { Modal, Button } from 'antd'

let sprtime = null
let wtObj = {}

const CommonTermial = () => {
  let [envir, setEnvir] = useState({})
  let [loadingInfo, setLoadingInfo] = useState('')
  let [restart, showRestart] = useState(false)
  let [progress, setProgress] = useState(0)
  let [terminalLoading, setTerminalLoading] = useState(false)
  let [terminalInfo, setTerminalInfo] = useState({})
  let [terminalInfoVisible, showTerminalInfo] = useState(false)
  let [RXT, setRXT] = useState('')
  let [closeTerminal, setCloseTerminal] = useState(false)
  let mst = 0
  /*
   * 函数调用
   */
  useEffect(() => {
    sprtime = null
    wtObj = {}
    getEnvir()
  }, [])

  const progr = {
    speed: 400,
    prig: 0,
    start: () => {
      window.TMWorker.clearInterval(sprtime)
      sprtime = window.TMWorker.setInterval(() => {
        progr.prig += 1
        setProgressStep(progr.prig)
      }, progr.speed)
    },
    stop: () => {
      progr.speed = 400
      window.TMWorker.clearInterval(sprtime)
      sprtime = null
    },
  }

  /*
   * 设置进度条
   */
  const setProgressStep = (st) => {
    if (st >= 100) {
      progr.prig = 100
    } else {
      if (progr.prig > 85) {
        progr.prig += 1
        if (progr.prig > 95) {
          progr.prig = 95
        }
      } else {
        progr.prig += st
        if (progr.prig > 95) {
          progr.prig = 95
        }
      }
    }
    setProgress(progr.prig)
  }

  /*
   * 获取environment
   */

  const getEnvir = async () => {
    const name = sessionStorage.getItem('__Top_AI_JupyterLab_Plug__')
    if (name) {
      const { data } = await get({
        name: name,
      })
      if (data.errCode === 0) {
        setEnvir(data.data.list[0])
        start(data.data.list[0])
      }
    }
  }

  /*
   * 刷新
   */
  const doRestart = () => {
    window.location.reload()
  }

  const start = async (envir) => {
    let obj = await changeEnvi(envir)
    console.log(obj, wtObj, '====888888===')
    if (obj.type === 0) {
      console.log('==000==')
      return
    } else if (obj.type === 2) {
      console.log('==222==')
      create(obj, envir)
    } else {
      console.log('==111==')
      create(obj, envir)
    }
  }

  // 终端释放
  window.__WSY.clearTerminal = () => {
    wtObj = {}
    progr.prig = 0
    setProgressStep(10)
    progr.delete = true
    progr.stop()
    progr.speed = 3000
    setTerminalLoading(true)
    console.log('释放2')
    setRXT('实验环境释放中...')
  }

  // 终端创建成功
  window.__WSY.createdTerminal = (row) => {
    console.log(row, '---0000-----')
    outURL(row)
  }

  // 服务释放完成可用ip
  window.__WSY.clearTerminalService = () => {
    if (wtObj.cb && !wtObj.op) {
      wtObj.op = true
      wtObj.cb()
    }
  }

  // 终端释放完成
  window.__WSY.clearTerminalEnd = () => {
    sendStartCreateMessage()
  }

  const sendStartCreateMessage = () => {
    let ms = new Date().getTime() - mst
    mst = new Date().getTime()
    console.log(ms, '------message-create------')
    if (ms < 300) {
      return
    }

    setRXT('实验环境已被释放！')

    setTerminalLoading(true)
    sessionStorage.removeItem('__terming_obj__')
    const o = JSON.stringify({
      phone: Cookies.get('username'),
      evid: envir._id,
      status: 'create',
    })
    window.__WSY.send(o)
    progr.prig = 50
    progr.speed = 100
    progr.start()
    setRXT('正在重建实验环境...')
    window.TMWorker.setTimeout(() => {
      window.__WSY.send(
        JSON.stringify({
          phone: Cookies.get('username'),
          evid: envir._id,
          status: 'create',
        })
      )
      create({}, envir);
    }, 500)
  }

  /*
   * 初始化终端
   */
  const create = async (obj, envir) => {
    const ct = envir.content.filter((a) => a.match(/kind:\s?Service/g))
    console.log(ct[0].match(/\$\{Port\}/gi), '--666--')
    const res = await createK8S({
      image: envir._id,
      evid: envir.uid,
      userId: Cookies.get('userId'),
      phone: Cookies.get('username'),
      type: ct[0].match(/\$\{Port\}/gi) ? 1 : 0,
      ip: obj.ip || '',
      Pip: obj.Pip || '',
    })

    if (res.data.errCode === 0 && String(res.data.data)) {
      if (res.data.data === 0) {
        setLoadingInfo(res.data.message)
        showRestart(true)
        setProgressStep(100)
        progr.stop()
        return
      }
      if (res.data.data && res.data.data.enable === 0) {
        setLoadingInfo(
          <span>
            <InfoCircleOutlined className="icon" />
            您的实验环境已被禁用，请联系管理员！
          </span>
        )
        progr.stop()
        setProgressStep(100)
        return
      }
      if (res.data.errCode === 0 && res.data.message && res.data.message !== 'success') {
        setLoadingInfo(
          <span>
            <InfoCircleOutlined className="icon" />
            {res.data.message}
          </span>
        )
        progr.stop()
        setProgressStep(100)
        return
      }

      const port = res.data.data.ssh ? res.data.data.ssh.split('/')[2] : '18888'
      const psmsg = {
        imageId: envir._id,
        evid: envir.uid,
        userId: Cookies.get('userId'),
        phone: Cookies.get('username'),
        ip: res.data.data.ip,
        Pip: res.data.data.Pip,
        port,
      }
      window.__WSY.send(JSON.stringify(psmsg))
    } else {
      window.TMWorker.setTimeout(() => {}, 2000)
    }
  }

  const rmIframe = () => {
    const a = document.querySelector('.jept-frame_box')
    a.innerHTML = ''
  }

  /*
   *  发送soket信息
   */
  const awtObj = (userId, wtObj, create) => {
    const o = JSON.stringify({
      userId: userId,
      imageId: wtObj.imageId,
      phone: Cookies.get('username'),
      evid: wtObj.evid,
      ip: wtObj.ip,
      Pip: wtObj.Pip,
      port: wtObj.port,
      type: wtObj.type || '',
      delete: create ? false : true,
    })
    window.__WSY.send(o)
  }

  /*
   *  切换环境
   */
  const changeEnvi = (envir) => {
    return new Promise(async (resolve) => {
      const userId = Cookies.get('userId')
      const { data } = await getUsedCase({
        userId: userId,
        envid: envir._id,
      })
      let evnid = ''
      if (data.data.length > 0) {
        const wtd = data.data[0]
        evnid = wtd.environment
        console.log(data.data, 'getUsedCase')
        wtObj = sessionStorage.getItem('__terming_obj__')
        if (wtObj) {
          console.log(JSON.parse(wtObj), '====4id====')
          wtObj = JSON.parse(wtObj)
          wtObj.ip = wtd.ip
          wtObj.op = false
          if (evnid === String(envir._id)) {
            console.log('same3')
            rmIframe()
            Modal.confirm({
              content:
                '当前章节环境与已开启的环境兼容，环境数据可继续沿用，不沿用，该环境和代码将被删除，如需保存环境建议沿用。',
              okText: '确定沿用',
              cancelText: '不沿用',
              centered: true,
              onOk: async () => {
                setTerminalLoading(true)
                setRXT('初始化实验环境...')
                setProgressStep(0)
                progr.start()
                awtObj(userId, wtObj, 'create')
                outURL(wtObj)
              },
              onCancel: async () => {
                setTerminalLoading(true)
                console.log('释放1')
                setRXT('实验环境释放中...')
                setProgressStep(20)
                wtObj.type = 2

                wtObj.pvc = 1
                awtObj(userId, wtObj)

                await excludeByUserId({
                  userId: userId,
                  imageId: envir._id,
                  pvc: true,
                })
              },
            })
          } else if (evnid !== String(envir._id)) {
            console.log('same4')
            rmIframe()
            Modal.confirm({
              content: '1个实验环境正在运行中，是否停止它启动新的环境？',
              okText: '确定',
              cancelText: '取消',
              centered: true,
              onOk: async () => {
                setTerminalLoading(true)
                setRXT('初始化实验环境...')
                setProgressStep(0)
                progr.start()

                wtObj.type = 1

                await excludeByUserId({
                  userId: userId,
                  imageId: envir._id,
                  pvc: false,
                })
                resolve(wtObj)
              },
              onCancel: async () => {
                wtObj.type = 0
                resolve(wtObj)
              },
            })
          }
          wtObj.evid = envir.uid
        } else {
          rmIframe()
          const port = wtd.ssh ? wtd.ssh.split('/')[2] : '18888'
          console.log('same5')
          wtObj = {
            Pip: wtd.Pip,
            evid: envir.uid,
            imageId: envir._id,
            ip: wtd.ip,
            op: false,
            phone: Cookies.get('username'),
            port: port,
            userId: wtd.user,
          }
          setTerminalLoading(true)
          setRXT('初始化实验环境...')
          setProgressStep(0)
          progr.start()
          outURL(wtObj)
        }
      } else {
        rmIframe()
        setTerminalLoading(true)
        setRXT('初始化实验环境...')
        setProgressStep(0)
        progr.start()
        wtObj = {}
        wtObj.op = false
        wtObj.evid = envir.uid
        wtObj.type = 3
        resolve(wtObj)
      }
    })
  }

  const checkUriStatus = (src, callback) => {
    const WS = new WebSocket('ws://' + window.location.hostname + ':9090/ws/checkUrlStatus')
    WS.onopen = () => {
      if (WS.readyState === 1) {
        WS.send(src)
      }
    }
    WS.onmessage = (e) => {
      if (e.data === 'open') return
      if (e.data * 1 === 200) {
        callback(true)
        WS.close()
      } else {
        callback(false)
      }
    }
  }

  const outURL = (val) => {
    try {
      const ipa = val.Pip ? val.Pip.split(':') : val.ip.split(':')
      const IP = ipa[0]
      let port = ''
      let port2 = ''

      if (ipa && ipa.length > 1) {
        port = '18' + ipa[1]
        port2 = ipa[1]
      } else {
        port = val.port
        port2 = val.port
      }

      const url = document.location.protocol + '//' + IP + ':' + port

      const cs = 'aivmc-' + val.imageId
      let crm = document.querySelector('.' + cs)
      const div = document.querySelector('.jept-frame_box')
      console.log(div, 'div00000')
      if (sessionStorage.getItem('__view_modal__') * 1 === 0) {
        const imageInfo = envir.image
        if (imageInfo) {
          const ssh = imageInfo.sshInfo.split('/')
          setTerminalInfo({
            ip: IP,
            port: port2,
            desc: envir.image
              ? (envir.image.desc || '').replace(/\r|\n/gi, '<br />').replace(/\$\{Port\}/gi, port2)
              : '',
            username: ssh[0],
            password: ssh[1],
          })
        }
      } else {
        setTerminalInfo({
          ip: IP,
          port: port2,
          desc: envir.image ? (envir.image.desc || '').replace(/\r|\n/gi, '<br />').replace(/\$\{Port\}/gi, port2) : '',
          username: 'root',
          password: Cookies.get('encc'),
        })
      }

      if (!crm) {
        checkUriStatus(url, async (v) => {
          crm = document.querySelector('.' + cs)
          console.log(v, url, '====vvv======')
          if (v) {
            setProgressStep(100)
            let obj = {}
            obj = val
            sessionStorage.setItem('__terming_obj__', JSON.stringify(obj))
            // sessionStorage.setItem('__step_id__', stepInfo._id)
            setTerminalLoading(false)
            console.log('loadframe')
            if (!crm) {
              crm = document.createElement('iframe')
              crm.className = cs
              div.appendChild(crm)
              crm.src = url
            } else {
              crm.style.display = 'block'
            }
            progr.delete = false
            progr.stop()
          } else {
            setProgressStep(90)
            let timer = window.TMWorker.setInterval(() => {
              checkUriStatus(url, async (v) => {
                console.log(v, '====vvv======')
                crm = document.querySelector('.' + cs)
                if (v) {
                  let obj = {}
                  obj = val
                  sessionStorage.setItem('__terming_obj__', JSON.stringify(obj))
                  setTerminalLoading(false)
                  if (!crm) {
                    crm = document.createElement('iframe')
                    crm.className = cs
                    crm.src = url
                    div.appendChild(crm)
                  } else {
                    crm.style.display = 'block'
                  }
                  progr.delete = false
                  progr.stop()
                  window.TMWorker.clearInterval(timer)
                }
              })
            }, 5000)
          }
        })
      } else {
        setProgressStep(100)
        crm.style.display = 'block'
        let obj = {}
        obj = val
        sessionStorage.setItem('__terming_obj__', JSON.stringify(obj))
        setTerminalLoading(false)
        progr.delete = false
        progr.stop()
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const close = () => {
    setCloseTerminal(!closeTerminal)
  }

  return (
    <div className={closeTerminal ? 'terminal-jept-frame gx' : 'terminal-jept-frame'}>
      <div
        className="close"
        onClick={() => {
          close()
        }}
      >
        {closeTerminal ? <LeftOutlined /> : <RightOutlined />}
      </div>
      <div className="jept-frame_wrap">
        <div className="jept-frame_box"></div>
        {terminalLoading}
        {terminalLoading ? (
          <div className="progress-aox">
            {loadingInfo ? (
              ''
            ) : (
              <div className="progress-loading">
                <div className="c" style={{ width: progress + '%' }}></div>
              </div>
            )}
            <div className="msg">
              {loadingInfo ? (
                restart ? (
                  <span>
                    {loadingInfo}
                    <Button type="primary" size="small" onClick={() => doRestart()}>
                      刷新
                    </Button>
                  </span>
                ) : (
                  loadingInfo
                )
              ) : (
                RXT
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* ....环境信息弹窗.... */}
      <Modal
        width={580}
        centered
        title="环境信息"
        visible={terminalInfoVisible}
        onCancel={() => {
          showTerminalInfo(false)
        }}
        footer={null}
      >
        <div className="terminal-user-info">
          <div>
            <p>
              <label>账&nbsp;&nbsp;&nbsp;号：</label>
              {terminalInfo.username}
            </p>
            <p>
              <label>密&nbsp;&nbsp;&nbsp;码：</label>
              {terminalInfo.password}
            </p>
            <p>
              <label>IP地址：</label>
              {terminalInfo.ip}
            </p>
          </div>
          <div>
            {terminalInfo.desc ? (
              <p>
                <label style={{ width: '60px' }}>说明：</label>
                <span style={{ flex: '1' }} dangerouslySetInnerHTML={{ __html: terminalInfo.desc }}></span>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CommonTermial
