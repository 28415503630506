import axios from '@/api/axios'

// 获取实验记录
export const getLog = (params) => {
    return axios({
        method: 'GET',
        url: '/api/log',
        params,
    })
}

// 创建实验记录
export const createLog = (data) => {
    return axios({
        method: 'POST',
        url: '/api/log',
        data,
    })
}

// 更新实验记录
export const updateLog = (params) => {
    return axios({
        method: 'POST',
        url: '/api/log/update',
        params,
    })
}