import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '@/store'
import './styles/index.less'
import App from './Router/App'
import reportWebVitals from './reportWebVitals'
import ScheduleWorker from '@/utils/scheduleWorker'
import 'antd/dist/antd.css'
import zhCN from 'antd/lib/locale/zh_CN'
import * as Lang from '@/utils/lang'
import 'moment/locale/zh-cn'
import moment from 'moment'
import { getDicts } from '@/api/env'
import { ConfigProvider, message } from 'antd'


moment.locale('zh-cn')
window.TMWorker = ScheduleWorker
window.__firstintopage__ = true
window.__Platform_Config__ = Lang
window.__Platform_Themes__ = ''
window.__Platform_Modal__ = 1
window.__History_change__ = 0
window.__Platform_Modal_Config__ = {}
window.__Major_BTN_Name__ = ''


const setWindData = (data) => {
  sessionStorage.setItem('__ENV_ALL_CONFIG__', JSON.stringify(data))
  if (data['View-Modal']) {
    const modal = data['View-Modal']
    sessionStorage.setItem('__view_modal__', modal.Key || 0)
  }
  if (data['Interface_Control']) {
    const Interface_Control = data['Interface_Control']
    document.title = Interface_Control['Platform_Title'] || ''
    localStorage.setItem('__Platform_Title__', Interface_Control['Platform_Title'] || '')
    sessionStorage.setItem('__Left_Menu_Footer__', Interface_Control['Left_Menu_Footer'] || '')
    sessionStorage.setItem('__Copy_Right__', Interface_Control['Copy_Right'] || '')
    sessionStorage.setItem('__Top_Ai_Jupyter__', Interface_Control.Top_AI_Jupyter || 0)
    sessionStorage.setItem('__Top_AI_JupyterLab_Plug__', Interface_Control.Top_AI_JupyterLab_Plug || '')
    sessionStorage.setItem('__Platform_Themes_Type__', Interface_Control['Themes-Type'] || 'default/study')
    sessionStorage.setItem('__Platform_Modal__', Interface_Control['Modal-Type'] || 1)
    sessionStorage.setItem('__Major_BTN_Name__', Interface_Control['Major_BTN_Name'] || '')
    sessionStorage.setItem('__Hide_Time_Downe__', Interface_Control['Hide_Time_Down'] || 0)
    sessionStorage.setItem('__Hide_Submit_Task__', Interface_Control['Hide_Submit_Task'] || 0)
    window.__Hide_Time_Downe__ = Interface_Control['Hide_Time_Down'] ? Interface_Control['Hide_Time_Down'] * 1 : 0
    window.__Hide_Submit_Task__ = Interface_Control['Hide_Submit_Task'] ? Interface_Control['Hide_Submit_Task'] * 1 : 0
    window.__Platform_Modal__ = Interface_Control['Modal-Type'] ? Interface_Control['Modal-Type'] * 1 : 1
    window.__Platform_Themes__ = Interface_Control['Themes-Type'] || 'default/study'
    window.__Platform_Modal_Config__ = window.__Platform_Config__[Interface_Control['Modal-Type'] || 1]

    if (Interface_Control['Major_BTN_Name']) {
      const o = {}
      const s = Interface_Control['Major_BTN_Name'].split(',')
      s.forEach((r) => {
        const b = r.split(':')
        o[b[0]] = b[1]
      })
      window.__Major_BTN_Name__ = o
    }
  }
  if (data['Permission_Tree']) {
    sessionStorage.setItem('__Permission_Tree__', JSON.stringify(data.Permission_Tree))
  }
  if (data['Single_Login']) {
    sessionStorage.setItem('__Single_Login_Type__', data.Single_Login['Login-Type'])
  }
}

const gotDicts = async () => {
  const { data } = await getDicts()
  if (data.errCode === 0) {
    setWindData(data.data)
    ReactDOM.render(
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <App />
        </Provider>
      </ConfigProvider>,
      document.getElementById('root')
    )
  } else {
    message.error('网络错误！')
  }
}

gotDicts()

if (sessionStorage.getItem('__Platform_Themes_Type__')) {
  window.__Platform_Themes__ = sessionStorage.getItem('__Platform_Themes_Type__')
}

if (sessionStorage.getItem('__Major_BTN_Name__')) {
  const a = sessionStorage.getItem('__Major_BTN_Name__')
  const o = {}
  const s = a.split(',')
  s.forEach((r) => {
    const b = r.split(':')
    o[b[0]] = b[1]
  })
  window.__Major_BTN_Name__ = o
}

// if (sessionStorage.getItem('__Platform_Modal__')) {
//   window.__Platform_Modal__ = sessionStorage.getItem('__Platform_Modal__') * 1
//   window.__Platform_Modal_Config__ = window.__Platform_Config__[sessionStorage.getItem('__Platform_Modal__') || 1]
// }
// if (localStorage.getItem('__Platform_Title__')) {
//   document.title = localStorage.getItem('__Platform_Title__')
// }

// if (sessionStorage.getItem('__Hide_Submit_Task__')) {
//   window.__Hide_Submit_Task__ = sessionStorage.getItem('__Hide_Submit_Task__')
//     ? sessionStorage.getItem('__Hide_Submit_Task__') * 1
//     : 0
// }

// if (sessionStorage.getItem('__Hide_Time_Downe__')) {
//   window.__Hide_Time_Downe__ = sessionStorage.getItem('__Hide_Time_Downe__')
//     ? sessionStorage.getItem('__Hide_Time_Downe__') * 1
//     : 0
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
