import actionType from './constant'

const initialState = {
  addResourceSuccess: false,
  addOrEditMessage: 'add', // 'add' | 'edit'
  addOrEditChapter: 'add', // 'add' | 'edit'
  addOrEditStep: 'add', // 'add' | 'edit'
  current_form_status: 'add', // 'add' | 'edit'
  editType: actionType.EDIT_MESSAGE, // 编辑资源信息 || 编辑资源章节
  editTreeType: actionType.EDIT_TREE_CHAPTER, // 编辑新章节 || 编辑新步骤
  treesData: [],
  currentMessageId: '',
  currentChapterId: '',
  currentStepId: '',
  // 资源表达
  messageFormData: [
    {
      name: '_id',
      value: '',
    },
    {
      name: 'name',
      value: '',
    },
    {
      name: 'overview',
      value: '',
    },
    {
      name: 'purpose',
      value: '',
    },
    {
      name: 'ResourceCardTotalData',
      value: {},
    },
    {
      name: 'paper',
      value: {},
    },
  ],
  // 章节表单
  chapterFormData: [
    {
      name: 'parentMessageId',
      value: '',
    },
    {
      name: '_id',
      value: '',
    },
    {
      name: 'key',
      value: `${+new Date()}`,
    },
    {
      name: 'current_form_status',
      value: ''
    },
    {
      name: 'name',
      value: '新章节',
    },
    {
      name: 'describe',
      value: '',
    },
    {
      name: 'info',
      value: {
        node: {
          key: '0',
        },
      },
    },
  ],
  // 步骤表单
  // 1. 每添加一个字段，要修改三处 (添加步骤) (点击步骤) (修改表单)
  stepFormData: [
    {
      name: 'parentChapterId',
      value: '',
    },
    {
      name: '_id',
      value: '',
    },
    {
      name: 'key',
      value: `child-${+new Date()}`,
    },
    {
      name: 'current_form_status',
      value: ''
    },
    {
      name: 'name',
      value: '新步骤',
    },
    {
      name: 'score',
      value: '0',
    },
    {
      name: 'type',
      value: 'file', // file | image | question
    },
    {
      name: 'content',
      value: [], // file | image | question
    },
    {
      name: 'time',
      value: '0',
    },
    {
      name: 'exercises', // 习题 => 习题类型
      value: 'TRUE_OR_FALSE',
    },
    {
      name: 'addDocument', // 课件 => 添加文档，file
      value: '',
    },
    {
      name: 'images', // 镜像
      value: [],
    },
    {
      name: 'plugargs', // plugargs
      value: '',
    },
    {
      name: 'jumeurl', // jumeurl
      value: '',
    },
    {
      name: 'pkg', // pkg
      value: '',
    },
    {
      name: 'openType', // openType
      value: '',
    },
    {
      name: 'questions', // 问题
      value: [],
    },
    {
      name: 'stepTotalData', // step总数据
      value: {},
    },
    {
      name: 'info',
      value: {
        node: {
          key: '0',
          courseType: '1',
        },
      },
    },
  ], // 步骤表单
  currentFileData: {
    name: '',
    path: '',
  }
}

const resourceManagement = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CHANGE_ADDRESOURCESUCCESS: // 资源信息保存成功
      return {
        ...state,
        addResourceSuccess: action.payload,
        editType: actionType.EDIT_CHAPTER,
      }
    case actionType.CHANGE_ADD_OR_EDIT_MESSAGE: // 添加/修改 => 资源
      return {
        ...state,
        addOrEditMessage: action.payload,
      }
    case actionType.CHANGE_ADD_OR_EDIT_CHAPTER: // 添加/修改 => 章节
      return {
        ...state,
        addOrEditChapter: action.payload,
      }
    case actionType.CHANGE_ADD_OR_EDIT_STEP: // 添加/修改 => 步骤
      return {
        ...state,
        addOrEditStep: action.payload,
      }
    case actionType.SET_MESSAGE_FORM_DATA: // messageForm
      return {
        ...state,
        messageFormData: action.payload,
      }
    case actionType.CHANGE_EDIT_TYPE: // button类型
      return {
        ...state,
        editType: action.payload,
      }
    case actionType.ADD_TREE_CHAPTER: // 添加章节
      return {
        ...state,
        treesData: action.payload,
      }
    case actionType.Add_TREE_STEP: // 添加步骤
      return {
        ...state,
        treesData: action.payload,
      }
    case actionType.CHANGE_TREE_DATA: // 修改整个tree的数据，主要用在form改变后去设置tree
      return {
        ...state,
        treesData: action.payload,
      }
    case actionType.CHANGE_EDIT_TREE_TYPE: // 点击 章节 or 步骤
      return {
        ...state,
        editTreeType: action.payload,
      }
    case actionType.SET_CHAPTER_FORM_DATA: // 点击章节时，填充章节表单
      return {
        ...state,
        chapterFormData: action.payload,
      }
    case actionType.SET_STEP_FORM_DATA: // 点击步骤时，填充步骤表单
      return {
        ...state,
        stepFormData: action.payload,
      }
    case actionType.SET_CURRENT_FILE_DATA:
      return {
        ...state,
        currentFileData: action.payload,
      }
    case actionType.SET_CURRENT_MESSAGEID:
      return {
        ...state,
        currentMessageId: action.payload,
      }
    case actionType.SET_CURRENT_CHAPTERID:
      return {
        ...state,
        currentChapterId: action.payload,
      }
    case actionType.SET_CURRENT_STEPID:
      return {
        ...state,
        currentStepId: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default resourceManagement
