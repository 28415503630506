import actionType from './constant.js'

const initialState = {
  globalModalData: {
    title: '',
    content: '',
    footer: []
  },
  globalModalVisible: false,
  globalModalFormData: []
}

const globalModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_GLOBAL_MODAL_DATA:
      return {
        ...state,
        globalModalData: action.payload,
      }
    case actionType.CHANGE_GLOBAL_MODAL_VISIBLE:
      return {
        ...state,
        globalModalVisible: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default globalModalReducer
