import axios from 'axios'
import Cookies from 'js-cookie'
import { message } from 'antd'
import { getProcessStatus } from './axios.config'

const token = Cookies.get('token')

axios.defaults.timeout = 20000

const axiosInstance = axios.create({
  baseURL: '',
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    authorization: `Bearer ${token}`,
    'Content-Security-Policy': 'upgrade-insecure-requests',
    accept:
      'text/html,application/octet-stream,application/json,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
  },
})

// const deepCopy = (p: any, c?: any) => {
//   c = c || {};
//   for (const i in p) {
//     if (typeof p[i] === 'object') {
//       c[i] = (p[i].constructor === Array) ? [] : {};
//       deepCopy(p[i], c[i]);
//     } else {
//       if (typeof (p[i]) === 'string') {
//         c[i] = p[i].replace(/^\s+|\s+$/g, '')
//       } else {
//         c[i] = p[i]
//       }
//     }
//   }
//   return c;
// }

const getUInfo = () => {
  return new Promise(async (resovle) => {
    const res: any = await fetch(`/api/users/${Cookies.get('userId')}`, {
      method: 'GET',
      keepalive: true,
      cache: 'no-cache',
    })
    const data = await res.json()
    if (data.errCode === 0) {
      resovle(data.data.user)
    }
  })
}

const logout = async () => {
  await fetch('/api/users/logOut', {
    method: 'POST',
    body: JSON.stringify({ userId: Cookies.get('userId') }),
  })
  Cookies.remove('token')
  Cookies.remove('userId')
  Cookies.remove('userCode')
  Cookies.remove('name')
  Cookies.remove('username')
  Cookies.remove('terminal')
  Cookies.remove('userType')
  sessionStorage.clear()
  window.location.href = '/'
}

// 请求拦截
axiosInstance.interceptors.request.use(
  (config) => {
    if (!Cookies.get('token') && config.url !== '/api/env/getDicts') {
      window.location.pathname = '/login'
    }

    if ((config as any).timeout === 'no') {
      config.timeout = 100000000000
    }

    // if (!(config as any).url.match(/\/upload/g)) {
    //   if (config.data) {
    //     config.data = deepCopy(config.data)
    //   }
    //   if (config.params) {
    //     config.params = deepCopy(config.params)
    //   }
    // }

    config.headers.authorization = `Bearer ${Cookies.get('token')}`
    return config
  },
  (err: Error) => {
    return Promise.reject(err)
  }
)

// 响应拦截
axiosInstance.interceptors.response.use(
  async (response) => {
    const {
      status,
      data: { errCode, message: messages },
    } = response

    const loginType: any = sessionStorage.getItem('__Single_Login_Type__')
    if (Cookies.get('token') && loginType * 1 === 1) {
      const user: any = await getUInfo()
      if (user && user.token !== Cookies.get('token') && response.config.url !== 'api/users/login') {
        logout()
        return response
      }
    }

    getProcessStatus(status)
    if (!(response.data instanceof Blob)) {
      console.log('拦截器', response)
      if (errCode !== 0) {
        // message.error('请求失败')
        message.error(messages)
      }
    }

    return response
  },
  (err: Error) => {
    console.log('拦截器error', err)
    if (err.message.indexOf('401') > -1) {
      logout()
    } else {
      message.error(err.message)
    }
    return Promise.reject(err)
  }
)

export default axiosInstance
