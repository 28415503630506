const actionType = {
    GET_DICTS: 'GET_DICTS'
}

const initialState = {
    dict: sessionStorage.getItem('__ENV_ALL_CONFIG__') ? JSON.parse(sessionStorage.getItem('__ENV_ALL_CONFIG__')) : []
}

const Global = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_DICTS:
            return {
                ...state,
                dict: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default Global
