import React from 'react'
import { ConfigProvider } from 'antd'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import Login from '@/pages/Login/index'
import GlobalModal from '@/common/CommonModal'
import BasicLayout from '@/pages/Dashboard/basiclayout'

// Suspense + lazy 首页有闪烁问题
// const Login = React.lazy(() => import('@/pages/Login/index'));
// const BasicLayout = React.lazy(() => import('@/pages/Dashboard/basiclayout'))

function App() {
  return (
      <ConfigProvider autoInsertSpaceInButton={false}>
        <Router>
          <div className="App">
            <Switch>
              <Route path="/dashboard" component={BasicLayout} />
              <Route path="/login" component={Login} />
              <Route path="/*" render={() => <Redirect to="/login" />} />
            </Switch>
          </div>
        </Router>
        <GlobalModal />
      </ConfigProvider>
  )
}

export default App
