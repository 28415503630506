import axios from '@/api/axios'

// 获取实验环境地址
export const getCase = (params) => {
    return axios({
        method: 'GET',
        url: '/api/case',
        params,
    })
}

// 获取用户使用中实验环境
export const getUsedCase = (params) => {
    return axios({
        method: 'GET',
        url: '/api/case/getUsedCase',
        params,
    })
}

// 创建实验环境实例
export const createCase = (data) => {
    return axios({
        method: 'POST',
        url: '/api/case',
        data,
    })
}

// 禁用释放
export const updateCase = (params) => {
    return axios({
        method: 'get',
        url: '/api/case/update',
        params,
    })
}


// 创建实验环境地址
export const createK8S = (data) => {
    return axios({
        method: 'POST',
        url: '/api/case/createK8S',
        timeout: 'no',
        data,
    })
}

// 创建k8s快照
export const createK8Snapshot = (data) => {
    return axios({
        timeout: 'no',
        method: 'POST',
        url: '/api/case/createK8Snapshot',
        data,
    })
}


// 获取状态
export const getK8Status = (params) => {
    return axios({
        method: 'get',
        url: '/api/case/getK8Status',
        params,
    })
}



// 获取节点
export const getNodes = (params) => {
    return axios({
        method: 'get',
        url: '/api/case/getNodes',
        params,
    })
}

// 获取状态
export const podsRemove = (params) => {
    return axios({
        method: 'get',
        url: '/api/case/podsRemove',
        params,
    })
}


// 删除实验环境信息
export const deleteCase = (data) => {
    return axios({
        method: 'post',
        timeout: 'no',
        url: '/api/case/delete',
        data,
    })
}

// 删除用户相关实验环境信息
export const deleteAllCase = (data) => {
    return axios({
        method: 'post',
        timeout: 'no',
        url: '/api/case/deleteAllCase',
        data,
    })
}

// 删除非当前用户相关实验环境信息
export const excludeByUserId = (data) => {
    return axios({
        method: 'post',
        timeout: 'no',
        url: '/api/case/excludeByUserId',
        data,
    })
}
