import React, { useState, useEffect, useRef } from 'react'

import styles from './index.module.less'
import { Form, Button, message, Input } from 'antd'
import { VerifiedOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { getDicts } from '@/api/env'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import API from '@/utils/apiConfig'
import request from '@/utils/request'
import ReactSlideVerify from './slideVerify/index'
import img1 from '../../assets/images/slideVerify/img01.png'
import img2 from '../../assets/images/slideVerify/img02.png'
import img3 from '../../assets/images/slideVerify/img03.png'
import img4 from '../../assets/images/slideVerify/img04.png'
import img5 from '../../assets/images/slideVerify/img05.png'
import img6 from '../../assets/images/slideVerify/img06.png'
import img7 from '../../assets/images/slideVerify/img07.png'
import { setTimeout } from 'timers'

const { login } = API

const Login = (props) => {
  const dispatch = useDispatch()
  const formRef = useRef()
  let [showSlideVerify, setShowVerify] = useState(false)
  let [background, setBackground] = useState(false)
  let [logo, setLogo] = useState(false)

  const setWindData = (data) => {
    sessionStorage.setItem('__ENV_ALL_CONFIG__', JSON.stringify(data))
    if (data['View-Modal']) {
      const modal = data['View-Modal']
      sessionStorage.setItem('__view_modal__', modal.Key || 0)
    }
    if (data['Interface_Control']) {
      const Interface_Control = data['Interface_Control']
      document.title = Interface_Control['Platform_Title'] || ''
      localStorage.setItem('__Platform_Title__', Interface_Control['Platform_Title'] || '')
      sessionStorage.setItem('__Left_Menu_Footer__', Interface_Control['Left_Menu_Footer'] || '')
      sessionStorage.setItem('__Copy_Right__', Interface_Control['Copy_Right'] || '')
      sessionStorage.setItem('__Top_Ai_Jupyter__', Interface_Control.Top_AI_Jupyter || 0)
      sessionStorage.setItem('__Top_AI_JupyterLab_Plug__', Interface_Control.Top_AI_JupyterLab_Plug || '')
      sessionStorage.setItem('__Platform_Themes_Type__', Interface_Control['Themes-Type'] || 'default/study')
      sessionStorage.setItem('__Platform_Modal__', Interface_Control['Modal-Type'] || 1)
      sessionStorage.setItem('__Major_BTN_Name__', Interface_Control['Major_BTN_Name'] || '')
      sessionStorage.setItem('__Hide_Time_Downe__', Interface_Control['Hide_Time_Down'] || 0)
      sessionStorage.setItem('__Hide_Submit_Task__', Interface_Control['Hide_Submit_Task'] || 0)
      window.__Hide_Time_Downe__ = Interface_Control['Hide_Time_Down'] ? Interface_Control['Hide_Time_Down'] * 1 : 0
      window.__Hide_Submit_Task__ = Interface_Control['Hide_Submit_Task'] ? Interface_Control['Hide_Submit_Task'] * 1 : 0
      window.__Platform_Modal__ = Interface_Control['Modal-Type'] ? Interface_Control['Modal-Type'] * 1 : 1
      window.__Platform_Themes__ = Interface_Control['Themes-Type'] || 'default/study'
      window.__Platform_Modal_Config__ = window.__Platform_Config__[Interface_Control['Modal-Type'] || 1]

      if (Interface_Control['Major_BTN_Name']) {
        const o = {}
        const s = Interface_Control['Major_BTN_Name'].split(',')
        s.forEach((r) => {
          const b = r.split(':')
          o[b[0]] = b[1]
        })
        window.__Major_BTN_Name__ = o
      }

      setBackground(
        window.__Platform_Themes__ ? 'url(/themes/' + window.__Platform_Themes__ + '/login_background.png)' : ''
      )
      setLogo('/themes/' + window.__Platform_Themes__ + '/logo2.svg')
    }
    if (data['Permission_Tree']) {
      sessionStorage.setItem('__Permission_Tree__', JSON.stringify(data.Permission_Tree))
    }
    if (data['Single_Login']) {
      sessionStorage.setItem('__Single_Login_Type__', data.Single_Login['Login-Type'])
    }
    dispatch({
      type: 'GET_DICTS',
      payload: data,
    })
  }

  const gotDicts = async () => {
    const { data } = await getDicts()
    if (data.errCode === 0) {
      setWindData(data.data)
    }
  }

  const getSearch = () => {
    const s = props.location.search.split('?')[1]
    const a = s.split('&')
    const o = {}
    a.forEach((c) => {
      const b = c.split('=')
      o[b[0]] = b[1]
    })
    return o
  }

  const autoLogin = () => {
    if (props.location.search) {
      const s = getSearch()
      if (s && s.u && s.p && formRef.current) {
        formRef.current.setFieldsValue({
          username: s.u,
          password: s.p,
        })
        onFinish({
          username: s.u,
          password: s.p,
        })
      }
    }
  }

  useEffect(async () => {
    if (sessionStorage.getItem('__ENV_ALL_CONFIG__')) {
      const envAllConfig = JSON.parse(sessionStorage.getItem('__ENV_ALL_CONFIG__'))
      setWindData(envAllConfig)
    } else {
      await gotDicts()
    }
    autoLogin()
  }, [])

  const onFinish = (values) => {
    request()
      .post(login, values)
      .then((res) => {
        if (res && res.data.errCode === 0) {
          let data = res.data.data

          Cookies.set('token', `${data.token}`, { expires: 7 })
          Cookies.set('username', `${data.user.username}`, { expires: 7 })
          Cookies.set('name', `${data.user.name || data.user.username || ''}`, { expires: 7 })
          Cookies.set('userType', data.user.type || 'teacher', { expires: 7 })
          Cookies.set('userId', data.user._id || '', { expires: 7 })
          Cookies.set('userCode', data.user.code || '', { expires: 7 })
          Cookies.set('encc', data.user.password || '', { expires: 7 })
          if (data.user.link) {
            Cookies.set('terminal', '//' + data.user.link || '', { expires: 7 })
          }
          sessionStorage.removeItem('__terming_ip___')
          setTimeout(() => {
            props.history.push('/dashboard')
            message.success('登录成功')
          }, 400)
        } else {
          message.error((res && res.data.message) || '登录失败')
        }
      })
  }

  const [form] = Form.useForm()
  const onSuccess = () => {
    console.log('Success!')
    setTimeout(() => {
      setShowVerify(false)
      setVerifyStatus(true)
      form.validateFields(['verify'])
    }, 0)
  }
  const onFail = () => {
    console.log('Failed!')
  }
  const onRefresh = () => {
    console.log('Refresh!')
  }

  let imgVerify = (
    <div style={{ display: showSlideVerify ? '' : 'none' }} className={styles['slide-cont']}>
      <CloseOutlined
        onClick={() => {
          setShowVerify(false)
        }}
        className={styles['slide-close']}
      />
      <ReactSlideVerify
        l={40}
        r={8}
        w={300}
        h={155}
        accuracy={5}
        sliderText="向右滑动"
        imgs={[img1, img2, img3, img4, img5, img6, img7]}
        success={onSuccess}
        fail={onFail}
        refresh={onRefresh}
      />
    </div>
  )

  let [verifySuc, setVerifyStatus] = useState(false)
  let verifyBtn
  if (!verifySuc) {
    verifyBtn = (
      <div
        onClick={() => {
          setShowVerify(true)
        }}
        className={styles['check-bar']}
      >
        <VerifiedOutlined className={styles['check-icon']} />
        <span>点击验证</span>
      </div>
    )
  } else {
    verifyBtn = (
      <div className={styles['check-bar-suc']}>
        <CheckOutlined className={styles['check-icon']} />
        <span>验证通过</span>
      </div>
    )
  }

  const validatorImgVerify = (rule, value) => {
    console.log(rule)
    console.log(value)
    if (verifySuc) {
      return Promise.resolve()
    } else {
      return Promise.reject(new Error('fail!'))
    }
  }

  return (
    <div
      className={styles.login_page}
      style={{
        background: background,
        backgroundSize: 'cover',
      }}
    >
      {/* <header className={styles.login_header}>
                <img className={styles.login_logo} src={logo} alt='logo' />
                <div className={styles.login_user}>
                    <img src={userLogo} alt='user-logo' />
                    <span>未登录</span>
                </div>
            </header> */}
      <div className={styles.form_container}>
        <div className={styles.form_title}>{window.__Platform_Themes__ ? <img src={logo} alt="form-title" /> : ''}</div>
        <div className={styles.form_bg}>
          <div className={styles.form_tit}>系统登录</div>
          <Form
            ref={formRef}
            name="basic"
            form={form}
            onFinish={onFinish}
            initialValues={{ username: '', password: '' }}
          >
            <Form.Item
              // style={{ marginBottom: '37px' }}
              name="username"
              rules={[{ required: true, message: '请输入用户名/手机号！' }]}
            >
              <Input size="large" placeholder="用户名/手机号" />
            </Form.Item>

            <Form.Item
              // style={{ marginBottom: '0' }}
              name="password"
              rules={[{ required: true, message: '请输入登录密码！' }]}
            >
              <Input.Password size="large" placeholder="登录密码" />
            </Form.Item>
            <Form.Item name="verify" rules={[{ validator: validatorImgVerify, message: '请通过验证！' }]}>
              {verifyBtn}
            </Form.Item>
            {/* 图片验证 */}
            {imgVerify}
            <div className={styles.forgetPass}>
              <span
                onClick={() => {
                  message.info('请联系客服，进行密码重置操作')
                }}
              >
                忘记密码?
              </span>
            </div>
            <Form.Item>
              <Button size="large" style={{ width: '100%' }} type="primary" htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.copyRight} dangerouslySetInnerHTML={{ __html: sessionStorage.getItem('__Copy_Right__') }}></div>
    </div>
  )
}

export default Login
