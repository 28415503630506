const format = require('string-format');
format.extend(String.prototype);
const APILink = 'http://39.104.109.46:8000';

const API = {
    APILink,
    login: '/api/users/login',
    getLableList: '/api/classes',
    getLabelDetail: '/api/labels/{labelId}',
    deleteLabel: '/api/labels/{labelId}',
    getCourseList: '/api/courses-types',
    imgLink: 'http://39.104.109.46:9090',
    getCourseDetail: '/api/courses-types/{courseId}/courses',
    getCourseState: '/api/courses',
    getReportContent: '/api/reports/{reportId}',
    setScore: '/api/reports/{reportId}/set-score',
    addLabel: '/api/labels',
    myTasksList: '/api/students/{studentId}/tasks/{status}',
    myTaskStatistics: '/api/students/{studentId}/statistics',
    getTaskDetaik: '/api/resources/{resourceId}/chapters',
    stepDetail:'/api/chapters/{chapterId}/steps/{stepId}',
    addReport: '/api/tasks/{taskId}/reports',
    updateReport: '/api/tasks/{taskId}/reports/{reportId}',
    submitTask: '/api/tasks/{taskId}/records/{recordId}',
    submitTaskNoRecord: '/api/tasks/{taskId}/records',
    getTaskDetail: '/api/tasks/{taskId}',
    updateTime: '/api/tasks/{taskId}/records/{recordId}/updateTime',
    addAnswerList: '/api/tasks/{taskId}/steps/{stepId}/answers',
    getAnswerList: '/api/tasks/{taskId}/steps/{stepId}/answers/own',
    // startTask: '/api/',
    addClass: '/api/classes',
    updateClass: '/api/classes/{classId}',
    uploadImage: '/api/tasks/{taskId}/steps/{stepId}/answers/uploadAnswerImage',
    getWebshot: '/api/upload/getWebshot'
}
export default API;